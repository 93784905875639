import { i18n } from '@/main';
import { getGrpcClient } from '@/services/grpc-client';
import { State } from '@/utils';
import extractErrorMessage from '@/utils/handle-error';
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from '@firebase/auth';
import { CurrentUserService } from 'hiber-grpc';
import { CurrentUser } from 'hiber-grpc/src/customer_compiled/currentuser';
import latinize from 'latinize';
import { defineStore } from 'pinia';
import { useToast } from 'vue-toastification';
import { OrganizationPermission } from 'hiber-grpc/src/customer_compiled/permission';

interface UserStore {
  isAuthenticated: boolean;
  currentUser: CurrentUser | null;
  useLocalTokenAuth: boolean;
  authToken: string;
  state: State;
}

const toast = useToast();

export const useUserStore = defineStore('user', {
  state: () =>
    ({
      isAuthenticated: false,
      useLocalTokenAuth: !!localStorage.getItem('local-auth-token'),
      authToken: localStorage.getItem('local-auth-token')?.toString(),
      currentUser: null,
      state: State.READY,
    } as UserStore),
  actions: {
    fetchCurrentUser(forceReload = false): Promise<CurrentUser> {
      if (forceReload || !this.currentUser) {
        return new Promise<CurrentUser>((resolve, reject) => {
          return CurrentUserService.getInstance(getGrpcClient)
            .getCurrentUser()
            .then((result) => {
              this.currentUser = result;
              resolve(result);
            })
            .catch((error) => {
              toast.error(extractErrorMessage(error));
              return reject(error);
            });
        });
      } else {
        return Promise.resolve(this.currentUser);
      }
    },
    async signInWithEmailAndPassword(email: string, password: string) {
      try {
        await signInWithEmailAndPassword(getAuth(), email, password);
      } catch (error) {
        const errorMessage = i18n.global.t.call(this, `firebaseErrors.${error.code}`);
        if (error.code !== 'auth/multi-factor-auth-required') {
          toast.error(errorMessage);
        }
        throw error;
      }
    },
    async signInWithGoogle() {
      try {
        await signInWithPopup(getAuth(), new GoogleAuthProvider());
      } catch (error) {
        const errorMessage = i18n.global.t.call(this, `firebaseErrors.${error.code}`);
        toast.error(errorMessage);
        throw error;
      }
    },
    async signInWithToken(token: string) {
      this.useLocalTokenAuth = true;
      this.authToken = token;
      return this.fetchCurrentUser(true);
    },

    async signout() {
      if (this.useLocalTokenAuth) {
        localStorage.removeItem('local-auth-token');
        this.useLocalTokenAuth = false;
        this.isAuthenticated = false;
        this.authToken = '';
      } else {
        await getAuth().signOut();
      }
    },
  },
  getters: {
    user(state) {
      return state.currentUser;
    },
    userHasOrganization(state) {
      return !!state.currentUser?.organizations?.length;
    },
    userInvites(state) {
      return state.currentUser;
    },
    missionControlSettings(state) {
      if (state.currentUser) {
        return JSON.parse(state.currentUser.missionControlSettings);
      }
      return {};
    },
    userInitials(state) {
      let result = '';

      if (!state.currentUser) return result;

      const name = latinize(state.currentUser.name);

      if (name.length) {
        const initials = name.match(/\b\w/g) || [];
        result = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
      } else {
        result = state.currentUser.email.charAt(0).toUpperCase();
      }
      return result;
    },
    permissions(state) {
      return state.currentUser?.currentOrganizationPermissions;
    },
    hasPermissions(state) {
      return (permissions: OrganizationPermission[] = []) => {
        if (!permissions.length) return true;
        return (
          permissions.every((permission) =>
            state.currentUser?.currentOrganizationPermissions?.include?.includes?.(permission),
          ) || state.currentUser?.currentOrganizationPermissions?.includeAll
        );
      };
    },
  },
});
