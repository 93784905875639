import { useToast } from 'vue-toastification';
import { defineStore } from 'pinia';
import OrganizationService from 'hiber-grpc/src/services/organization';
import { getGrpcClient } from '@/services/grpc-client';
import { Organization, Organization_Feature } from 'hiber-grpc/src/customer_compiled/organization';
import extractErrorMessage from '@/utils/handle-error';
import { State } from '@/utils';
import { useDeviceMasterDetails } from '@/store/device-master-details';
import { useAssetMasterDetails } from '@/store/asset-master-details';

interface OrganizationStore {
  state: State;
  organizationImpersonation: string;
  organization: Organization | null;
}
const toast = useToast();

export const useOrganizationStore = defineStore('organization', {
  state: () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const toImpersonate = urlSearchParams.get('organization');

    return {
      state: State.DEFAULT,
      organizationImpersonation: toImpersonate || '',
      organization: null,
    } as OrganizationStore;
  },
  actions: {
    fetchOrganization(organization = '') {
      if ((!organization && this.organization) || organization === this.organization?.organization) {
        return Promise.resolve(this.organization);
      }
      return new Promise((resolve, reject) => {
        return OrganizationService.getInstance(getGrpcClient)
          .getOrganization({ organization })
          ?.then((result) => {
            const deviceMasterDetailsStore = useDeviceMasterDetails();
            const assetMasterDetailsStore = useAssetMasterDetails();
            this.organization = result;
            if (this.organizationImpersonation !== organization) {
              this.organizationImpersonation = organization;
              deviceMasterDetailsStore.setSelectedItem(null);
              assetMasterDetailsStore.setSelectedItem(null);
            }
            resolve(result);
          })
          .catch((error) => {
            toast.error(extractErrorMessage(error));
            return reject(error);
          });
      });
    },
  },
  getters: {
    currentOrganizationName(state) {
      return state.organization?.displayName || '';
    },
    organizationFeatures(state) {
      return state.organization?.features || [];
    },
    hasFeatures() {
      return (features: Organization_Feature[] = []) => {
        if (!features.length) return true;
        return features.every((feature) => this.organizationFeatures.includes(feature));
      };
    },
  },
});
