<script lang="ts">
import { OField } from '@oruga-ui/oruga-next';
import { computed, h } from 'vue';

export default {
  name: 'HField',
  props: {
    ...OField.props,
    error: { type: Boolean, default: false },
    errorMessage: { type: [String, Array, undefined], default: () => '' },
    required: { type: Boolean, default: false },
  },
  setup(props, { slots }) {
    const variant = computed(() => (props.error ? 'danger' : ''));
    const message = computed(() => {
      let message = '';
      if (typeof props.errorMessage === 'string') {
        message = props.errorMessage;
      } else if (Array.isArray(props.errorMessage)) {
        // currently only support one error message
        message = props.errorMessage[0];
      }
      return props.error ? message : '';
    });
    return () =>
      h(
        OField,
        {
          ...props,
          label: props.required ? props.label + '*' : props.label,
          variant: variant.value,
          message: message.value,
        },
        {
          ...slots,
        },
      );
  },
};
</script>
