import { Client } from 'nice-grpc-web';
import { DeepPartial } from '../customer_compiled/field_service';
import { GrpcClientFactoryType } from './types';
import {
  CertificateServiceDefinition,
  DeleteCertificateRequest,
  DeleteCertificateRequest_Response,
  ListCertificatesRequest,
  ListCertificatesRequest_Response,
  RenameCertificateRequest,
  RenameCertificateRequest_Response,
  UploadCertificateRequest,
  UploadCertificateRequest_Response
} from '../customer_compiled/certificate';

export default class CertificatesService {
  private static instance: CertificatesService;
  private readonly client: Client<typeof CertificateServiceDefinition>;

  private constructor(grpcClientFactory: GrpcClientFactoryType) {
    this.client = grpcClientFactory<typeof CertificateServiceDefinition>(
      CertificateServiceDefinition,
    );
  }

  static getInstance(grpcClientFactory: GrpcClientFactoryType) {
    if (!CertificatesService.instance) {
      CertificatesService.instance = new CertificatesService(grpcClientFactory);
    }

    return CertificatesService.instance;
  }

  list(payload: DeepPartial<ListCertificatesRequest>): Promise<ListCertificatesRequest_Response> {
    const request = ListCertificatesRequest.fromPartial(payload);
    return this.client.list(request);
  }

  upload(payload: DeepPartial<UploadCertificateRequest>): Promise<UploadCertificateRequest_Response> {
    const request = UploadCertificateRequest.fromPartial(payload);
    return this.client.upload(request);
  }

  delete(payload: DeepPartial<DeleteCertificateRequest>): Promise<DeleteCertificateRequest_Response> {
    const request = DeleteCertificateRequest.fromPartial(payload);
    return this.client.delete(request);
  }

  rename(payload: DeepPartial<RenameCertificateRequest>): Promise<RenameCertificateRequest_Response> {
    const request = RenameCertificateRequest.fromPartial(payload);
    return this.client.rename(request);
  }
}
