import { Client } from 'nice-grpc-web';
import { GrpcClientFactoryType } from './types';
import {
  CreateOrganizationRequest,
  DeepPartial,
  GetOrganizationAvatar_Request,
  GetOrganizationRequest,
  ListChildOrganizationsRequest,
  ListChildOrganizationsRequest_Response,
  Organization,
  OrganizationServiceDefinition,
  UpdateOrganizationRequest,
  ValidateOrganizationCreationTokenRequest,
  ValidateOrganizationCreationTokenRequest_Response,
} from '../customer_compiled/organization';

export default class OrganizationService {
  private static instance: OrganizationService;
  private readonly client: Client<typeof OrganizationServiceDefinition>;

  private constructor(grpcClientFactory: GrpcClientFactoryType) {
    this.client = grpcClientFactory<typeof OrganizationServiceDefinition>(OrganizationServiceDefinition);
  }

  static getInstance(grpcClientFactory: GrpcClientFactoryType) {
    if (!OrganizationService.instance) {
      OrganizationService.instance = new OrganizationService(grpcClientFactory);
    }

    return OrganizationService.instance;
  }

  getOrganization(payload: DeepPartial<GetOrganizationRequest> = {}): Promise<Organization> {
    const request = GetOrganizationRequest.fromPartial(payload);
    return this.client.get(request);
  }

  validateCreationToken(token: string): Promise<ValidateOrganizationCreationTokenRequest_Response> {
    const request = ValidateOrganizationCreationTokenRequest.fromPartial({ organizationCreationToken: token });
    return this.client.validateCreationToken(request);
  }

  createOrganization(payload: DeepPartial<CreateOrganizationRequest>) {
    const request = CreateOrganizationRequest.fromPartial(payload);
    return this.client.create(request);
  }

  getOrganizationsAvatar(payload: DeepPartial<GetOrganizationAvatar_Request>) {
    const request = GetOrganizationAvatar_Request.fromPartial(payload);
    return this.client.getAvatar(request);
  }

  getChildOrganizations(payload: DeepPartial<ListChildOrganizationsRequest>): Promise<ListChildOrganizationsRequest_Response> {
    const request = ListChildOrganizationsRequest.fromPartial(payload);
    return this.client.listChildOrganizations(request);
  }

  updateOrganization(payload: DeepPartial<UpdateOrganizationRequest>): Promise<Organization> {
    const request = UpdateOrganizationRequest.fromPartial(payload);
    return this.client.update(request);
  }
}
