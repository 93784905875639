import { getAuth, sendEmailVerification } from '@firebase/auth';

interface UseEmailVerificationType {
  verifyEmail: () => Promise<void>;
}

function useEmailVerification(): UseEmailVerificationType {
  const verifyEmail = () => {
    const { currentUser } = getAuth();

    if (currentUser) {
      return sendEmailVerification(currentUser, { url: document.location.origin });
    }
    return Promise.reject('User is not signed in. Cannot send verification email!');
  };

  return { verifyEmail };
}

export default useEmailVerification;
