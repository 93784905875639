import * as Sentry from '@sentry/vue';
import { getAuth } from '@firebase/auth';
import {
  CallOptions,
  Client,
  ClientError,
  ClientMiddlewareCall,
  CompatServiceDefinition,
  createChannel,
  createClientFactory,
  Metadata,
  Status,
} from 'nice-grpc-web';
import ExtendableError from 'ts-error';
import { initApp } from '@/firebase';
import useImpersonation from '@/hooks/useImpersonation';
import { unref } from 'vue';
import { useUserStore } from '@/store/user';

declare global {
  interface Window {
    __GRPCWEB_DEVTOOLS__: () => void;
  }
}

const hasGrpcWebDevTools = () => window && typeof window.__GRPCWEB_DEVTOOLS__ === 'function';

export async function userCheck(): Promise<string> {
  const fbApp = initApp();
  const userStore = useUserStore();

  if (userStore.useLocalTokenAuth) {
    const auth = `Bearer ${userStore.authToken}`;
    return Promise.resolve(auth);
  }

  const { currentUser } = getAuth(fbApp);
  if (currentUser) {
    const token = await currentUser.getIdToken();
    const auth = `Bearer ${token}`;
    return Promise.resolve(auth);
  } else {
    return Promise.reject('User is not authenticated');
  }
}

async function* grpcDevToolsMiddleware<Request, Response>(
  call: ClientMiddlewareCall<Request, Response>,
  options: CallOptions,
) {
  const { path } = call.method;

  try {
    const response = yield* call.next(call.request, options);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { request, ...rest } = response as any;
    if (hasGrpcWebDevTools()) {
      window.postMessage(
        {
          type: '__GRPCWEB_DEVTOOLS__',
          method: path,
          methodType: 'unary',
          request: call.request,
          response: response,
        },
        window.parent.origin,
      );
    }
    return response;
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { request, ...rest } = error;
    if (hasGrpcWebDevTools()) {
      window.postMessage(
        {
          type: '__GRPCWEB_DEVTOOLS__',
          method: path,
          methodType: 'unary',
          request: call.request,
          response: rest,
          error: rest,
        },
        window.parent.origin,
      );
    }
    throw error;
  }
}

async function* sentryLoggingMiddleware<Request, Response>(
  call: ClientMiddlewareCall<Request, Response>,
  options: CallOptions,
) {
  const fbApp = initApp();
  const organizationImpersonation = useImpersonation();

  const { currentUser } = getAuth(fbApp);

  // Log these things by default as extra info
  const extraLog = {
    user: currentUser?.email,
    impersonation: unref(organizationImpersonation),
  };
  const { path } = call.method;
  try {
    return yield* call.next(call.request, options);
  } catch (error) {
    if (error instanceof ClientError) {
      console.group('gRPC Request failed');
      console.log(`%cThe call to ${path} has failed!`, 'color: #E02F2F; font-weight: bold; font-size: 12px');
      console.log(`request: %O`, JSON.stringify(call.request, null, 2));
      console.groupEnd();

      if (error.message === 'Response closed without headers') {
        // Connection error
        Sentry.captureException('Connection error: Response closed without headers.', {
          extra: { ...extraLog, call: path },
        });
      } else if (!error.message) {
        // List under its GRPC code error.
        Sentry.captureException(`${Status[error.code]} error with no message`, {
          extra: { ...extraLog, call: path },
        });
      } else {
        // Regular error
        Sentry.captureException(error.stack, {
          extra: { ...extraLog, call: path },
        });
      }
    } else if (error instanceof ExtendableError) {
      Sentry.captureException(error.stack, {
        extra: { call: path },
      });
    }
    throw error;
  }
}

async function* authMiddleware<Request, Response>(call: ClientMiddlewareCall<Request, Response>, options: CallOptions) {
  // eslint-disable-next-line no-useless-catch
  try {
    const auth = await userCheck();
    options.metadata = Metadata({ authorization: auth });
    const organizationImpersonation = useImpersonation();
    const organizationToImpersonate = unref(organizationImpersonation);

    const { organization, groups, groupContent } = call.request as any;

    // special case when the request includes group requests {groups:{}, groupContent:{}}
    const isGroupRequest =
      groups &&
      groupContent &&
      Object.prototype.hasOwnProperty.call(groups, 'organization') &&
      Object.prototype.hasOwnProperty.call(groupContent, 'organization');
    if (isGroupRequest) {
      groups.organization = organizationToImpersonate;
      groupContent.organization = organizationToImpersonate;
    }

    if (!organization) {
      (call.request as any).organization = organizationToImpersonate;
    }

    return yield* call.next(call.request, options);
  } catch (e) {
    throw e;
  }
}

// eslint-disable-next-line prettier/prettier
const grpcClientFactory = createClientFactory().use(sentryLoggingMiddleware).use(authMiddleware).use(grpcDevToolsMiddleware);

const customerChannel = createChannel(import.meta.env.VITE_CUSTOMER_HOST);
const supportChannel = createChannel(import.meta.env.VITE_SUPPORT_HOST);

export const channels = {
  customer: customerChannel,
  support: supportChannel,
};

type ChannelsType = keyof typeof channels;

export const getGrpcFactory = <T extends CompatServiceDefinition>(
  channel: ChannelsType = 'customer',
): ((service: T) => Client<T>) => {
  const useChannel = channels[channel];

  return (service) => grpcClientFactory.create(service, useChannel);
};

export const getGrpcClient = <T extends CompatServiceDefinition>(
  service: T,
  channel: ChannelsType = 'customer',
): Client<T> => {
  const useChannel = channels[channel];

  return grpcClientFactory.create(service, useChannel);
};
