import { ClientError } from 'nice-grpc-web';
import { Status } from 'nice-grpc-common';

const internalErrorCodes = [
  Status.CANCELLED,
  Status.UNKNOWN,
  Status.ABORTED,
  Status.UNIMPLEMENTED,
  Status.INTERNAL,
  Status.UNAVAILABLE,
  Status.DATA_LOSS,
];
const jsonBasedMessage = (str: string) => {
  try {
    const details = JSON.parse(str);
    if (details && typeof details === 'object' && Object.prototype.hasOwnProperty.call(details, 'message')) {
      return details.message;
    } else if (typeof details === 'string') {
      return details;
    } else {
      return undefined;
    }
  } catch (e) {
    return undefined;
  }
};

function extractErrorMessage(error: ClientError): string {
  let generatedMessage = 'An error has occurred, but there is no message.';

  if (internalErrorCodes.includes(error.code)) {
    generatedMessage = 'Internal connection error: contact support if the problem persists.';
  } else if (jsonBasedMessage(error.details)) {
    generatedMessage = jsonBasedMessage(error.details);
  } else if (error.details) {
    generatedMessage = error.details;
  }
  return generatedMessage;
}

export default extractErrorMessage;
