import { Client } from 'nice-grpc-web';
import { GrpcClientFactoryType } from './types';
import {
  ApproveUserRequest,
  ApproveUserRequest_Response,
  CreateUserRequest,
  DeepPartial,
  InviteUserRequest,
  InviteUserRequest_Response,
  ListAccessRequestsRequest,
  ListAccessRequestsRequest_Response,
  ListInvitationsRequest,
  ListInvitationsRequest_Response,
  ListUsersRequest,
  ListUsersRequest_Response,
  RemoveUserRequest,
  RemoveUserRequest_Response,
  UpdateUserRoles_Request,
  UpdateUserRoles_Response,
  User,
  UserServiceDefinition
} from '../customer_compiled/user';

export default class UsersService {
  private static instance: UsersService;
  private readonly client: Client<typeof UserServiceDefinition>;

  private constructor(grpcClientFactory: GrpcClientFactoryType) {
    this.client = grpcClientFactory<typeof UserServiceDefinition>(
      UserServiceDefinition,
    );
  }

  static getInstance(grpcClientFactory: GrpcClientFactoryType) {
    if (!UsersService.instance) {
      UsersService.instance = new UsersService(grpcClientFactory);
    }

    return UsersService.instance;
  }

  getUsers(search: string, size: number, page: number) {
    const request = ListUsersRequest.fromPartial({
      pagination: { size, page },
      selection: { search },
    });
    return this.client.list(request);
  }

  createUser(
    name: string,
    email: string,
    password: string,
  ) {
    const request = CreateUserRequest.fromPartial({
      name,
      email,
      password,
    });
    return this.client.createUser(request);
  }

  inviteUser(
    email: string,
  ) {
    const request = InviteUserRequest.fromPartial({
      email,
      retry: true,
    });
    return this.client.invite(request);
  }

  getInvitedUsers(payload: DeepPartial<ListInvitationsRequest> = {}): Promise<ListInvitationsRequest_Response> {
    const request = ListInvitationsRequest.fromPartial(payload);
    return this.client.listInvitations(request);
  }

  approveRequestedUsers(
    organizationId: string,
    userIds: Array<string>,
  ) {
    const request = ApproveUserRequest.fromPartial({
      organization: organizationId,
      userIds,
    });
    return this.client.approve(request);
  }

  getRequestedUsers() {
    const request = ListAccessRequestsRequest.fromPartial({});
    return this.client.listAccessRequests(request);
  }

  updateRoles(payload: DeepPartial<UpdateUserRoles_Request>): Promise<UpdateUserRoles_Response> {
    const request = UpdateUserRoles_Request.fromPartial(payload);
    return this.client.updateUserRoles(request);
  }

  listAccessRequests(payload: DeepPartial<ListAccessRequestsRequest>): Promise<ListAccessRequestsRequest_Response> {
    const request = ListAccessRequestsRequest.fromPartial(payload);
    return this.client.listAccessRequests(request);
  }

  listUsers(payload: DeepPartial<ListUsersRequest>): Promise<ListUsersRequest_Response> {
    const request = ListUsersRequest.fromPartial(payload);
    return this.client.list(request);
  }

  removeUsers(payload: DeepPartial<RemoveUserRequest>): Promise<RemoveUserRequest_Response> {
    const request = RemoveUserRequest.fromPartial(payload);
    return this.client.remove(request);
  }

  approveUsers(payload: DeepPartial<ApproveUserRequest>): Promise<ApproveUserRequest_Response> {
    const request = ApproveUserRequest.fromPartial(payload);
    return this.client.approve(request);
  }

  invite(payload: DeepPartial<InviteUserRequest>): Promise<InviteUserRequest_Response> {
    const request = InviteUserRequest.fromPartial(payload);
    return this.client.invite(request);
  }

  create(payload: DeepPartial<CreateUserRequest>): Promise<User> {
    const request = CreateUserRequest.fromPartial(payload);
    return this.client.createUser(request);
  }
}

