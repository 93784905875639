import { Client } from 'nice-grpc-web';
import { DeepPartial } from '../customer_compiled/field_service';
import { GrpcClientFactoryType } from './types';
import {
  CreateTokenRequest,
  CreateTokenRequest_Response,
  DeleteTokenRequest,
  DeleteTokenRequest_Response,
  ListTokensRequest,
  ListTokensRequest_Response,
  TokenServiceDefinition,
  UpdateTokenOrganizationPermissionsRequest,
  UpdateTokenOrganizationPermissionsRequest_Response,
  UpdateTokenUserPermissionsRequest,
  UpdateTokenUserPermissionsRequest_Response
} from '../customer_compiled/token';

export default class TokensService {
  private static instance: TokensService;
  private readonly client: Client<typeof TokenServiceDefinition>;

  private constructor(grpcClientFactory: GrpcClientFactoryType) {
    this.client = grpcClientFactory<typeof TokenServiceDefinition>(
      TokenServiceDefinition,
    );
  }

  static getInstance(grpcClientFactory: GrpcClientFactoryType) {
    if (!TokensService.instance) {
      TokensService.instance = new TokensService(grpcClientFactory);
    }

    return TokensService.instance;
  }

  list(payload: DeepPartial<ListTokensRequest>): Promise<ListTokensRequest_Response> {
    const request = ListTokensRequest.fromPartial(payload);
    return this.client.list(request);
  }

  create(payload: DeepPartial<CreateTokenRequest>): Promise<CreateTokenRequest_Response> {
    const request = CreateTokenRequest.fromPartial(payload);
    return this.client.create(request);
  }

  delete(payload: DeepPartial<DeleteTokenRequest>): Promise<DeleteTokenRequest_Response> {
    const request = DeleteTokenRequest.fromPartial(payload);
    return this.client.delete(request);
  }

  updateOrganizationPermissions(payload: DeepPartial<UpdateTokenOrganizationPermissionsRequest>): Promise<UpdateTokenOrganizationPermissionsRequest_Response> {
    const request = UpdateTokenOrganizationPermissionsRequest.fromPartial(payload);
    return this.client.updateTokenOrganizationPermissions(request);
  }

  updateUserPermissions(payload: DeepPartial<UpdateTokenUserPermissionsRequest>): Promise<UpdateTokenUserPermissionsRequest_Response> {
    const request = UpdateTokenUserPermissionsRequest.fromPartial(payload);
    return this.client.updateTokenUserPermissions(request);
  }
}
