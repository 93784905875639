import { Client } from 'nice-grpc-web';
import { GrpcClientFactoryType } from './types';
import { DeepPartial, } from '../customer_compiled/modem_alarm';
import {
  AssetServiceDefinition,
  CreateAsset_Request,
  CreateAsset_Response,
  DeleteAsset_Request,
  DeleteAsset_Response,
  ListAsset_Request,
  ListAsset_Response,
  UpdateAsset_Request,
  UpdateAsset_Response
} from '../customer_compiled/asset_service';

export default class AssetsService {
  private static instance: AssetsService;
  private readonly client: Client<typeof AssetServiceDefinition>;

  private constructor(grpcClientFactory: GrpcClientFactoryType) {
    this.client = grpcClientFactory<typeof AssetServiceDefinition>(
      AssetServiceDefinition,
    );
  }

  static getInstance(grpcClientFactory: GrpcClientFactoryType) {
    if (!AssetsService.instance) {
      AssetsService.instance = new AssetsService(grpcClientFactory);
    }

    return AssetsService.instance;
  }

  public list(payload: DeepPartial<ListAsset_Request>): Promise<ListAsset_Response> {
    const request = ListAsset_Request.fromPartial(payload);
    return this.client.list(request);
  }

  public delete(payload: DeepPartial<DeleteAsset_Request>): Promise<DeleteAsset_Response> {
    const request = DeleteAsset_Request.fromPartial(payload);
    return this.client.delete(request);
  }

  public update(payload: DeepPartial<UpdateAsset_Request>): Promise<UpdateAsset_Response> {
    const request = UpdateAsset_Request.fromPartial(payload);
    return this.client.update(request);
  }

  public create(payload: DeepPartial<CreateAsset_Request>): Promise<CreateAsset_Response> {
    const request = CreateAsset_Request.fromPartial(payload);
    return this.client.create(request);
  }
}
