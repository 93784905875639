import { Client } from 'nice-grpc-web';
import { DeepPartial } from '../customer_compiled/field_service';
import { GrpcClientFactoryType } from './types';
import {
  CreateSlackPublisherRequest,
  DeleteSlackPublisherRequest,
  DeleteSlackPublisherRequest_Response,
  DisableSlackPublisherRequest,
  DisableSlackPublisherRequest_Response,
  EnableSlackPublisherRequest,
  EnableSlackPublisherRequest_Response,
  ListSlackPublishersRequest,
  ListSlackPublishersRequest_Response,
  SlackIntegrationServiceDefinition,
  SlackPublisher,
  UpdateSlackPublisherRequest
} from '../customer_compiled/integration_slack';

export default class SlackIntegrationService {
  private static instance: SlackIntegrationService;
  private readonly client: Client<typeof SlackIntegrationServiceDefinition>;

  private constructor(grpcClientFactory: GrpcClientFactoryType) {
    this.client = grpcClientFactory<typeof SlackIntegrationServiceDefinition>(
      SlackIntegrationServiceDefinition,
    );
  }

  static getInstance(grpcClientFactory: GrpcClientFactoryType) {
    if (!SlackIntegrationService.instance) {
      SlackIntegrationService.instance = new SlackIntegrationService(grpcClientFactory);
    }

    return SlackIntegrationService.instance;
  }

  list(payload: DeepPartial<ListSlackPublishersRequest>): Promise<ListSlackPublishersRequest_Response> {
    const request = ListSlackPublishersRequest.fromPartial(payload);
    return this.client.list(request);
  }

  enable(payload: DeepPartial<EnableSlackPublisherRequest>): Promise<EnableSlackPublisherRequest_Response> {
    const request = EnableSlackPublisherRequest.fromPartial(payload);
    return this.client.enable(request);
  }

  disable(payload: DeepPartial<DisableSlackPublisherRequest>): Promise<DisableSlackPublisherRequest_Response> {
    const request = DisableSlackPublisherRequest.fromPartial(payload);
    return this.client.disable(request);
  }

  create(payload: DeepPartial<CreateSlackPublisherRequest>): Promise<SlackPublisher> {
    const request = CreateSlackPublisherRequest.fromPartial(payload);
    return this.client.create(request);
  }

  update(payload: DeepPartial<UpdateSlackPublisherRequest>): Promise<SlackPublisher> {
    const request = UpdateSlackPublisherRequest.fromPartial(payload);
    return this.client.update(request);
  }

  delete(payload: DeepPartial<DeleteSlackPublisherRequest>): Promise<DeleteSlackPublisherRequest_Response> {
    const request = DeleteSlackPublisherRequest.fromPartial(payload);
    return this.client.delete(request);
  }
}
