import { Directive } from 'vue';

const randomBgImage: Directive = {
  async beforeMount(el) {
    const bgImages = [
      import('../assets/hero/rice.jpg'),
      import('../assets/hero/rail.jpg'),
      import('../assets/hero/arctic.jpg'),
    ];
    const num = Math.floor(Math.random() * bgImages.length);

    const img = (await bgImages[num]).default;
    el.style.backgroundImage = `url(${img})`;
  },
};

export default randomBgImage;
