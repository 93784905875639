// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.19.1
// source: asset.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Location, TimeRange, Timestamp } from "./base";
import { File } from "./file";
import { Struct } from "./google/protobuf/struct";
import { HealthLevel } from "./health";
import { Tag, TagSelection } from "./tag";
import {
  Value_Numeric_Rate,
  Value_Numeric_Type,
  value_Numeric_TypeFromJSON,
  value_Numeric_TypeToNumber,
} from "./value";

export const protobufPackage = "hiber.asset";

/**
 * Assets are things that collect the data produced by devices.
 * Devices are assigned to assets to handle data ownership.
 * When a device is replaced, the data flow for the asset continues with the data from the new device.
 * Multiple devices can be assigned to an asset, though it is advisable to only do so when they send
 * different type of data (i.e. one sensor for pressure and one for flow).
 *
 * For example, if you have a Well, you might have assets for Annulus A and the tubing head.
 */
export interface Asset {
  identifier: string;
  /** Name of the asset */
  name: string;
  /** Type of the asset, if any of the predefined types applies. */
  type?:
    | Asset_Type
    | undefined;
  /** Longer detailed description of the asset. */
  description?:
    | string
    | undefined;
  /** Multiline notes field that can be used to add additional information to an asset. */
  notes?:
    | string
    | undefined;
  /**
   * Optional time zone for the asset.
   * This can, for example, be used to calculate SLAs on a daily basis, adjusted by time zone.
   */
  timeZone?:
    | string
    | undefined;
  /** The expected transmission rate for this asset. */
  expectedTransmissionRate?:
    | Value_Numeric_Rate
    | undefined;
  /**
   * Metadata for the asset.
   * This can be automatically populated from linked devices or manually added.
   */
  metadata:
    | { [key: string]: any }
    | undefined;
  /** Tags assigned to this asset */
  tags: Tag[];
  /** Devices assigned to this asset */
  devices: Asset_AssignedDevice[];
  /** Devices that were assigned to this asset in the past */
  inactiveDevices: Asset_AssignedDevice[];
  /**
   * The organization that owns this asset.
   * Typically only relevant if child organizations are included.
   */
  organization: string;
  /** Location for the asset. */
  location:
    | Location
    | undefined;
  /**
   * Files for this asset.
   * Typically an image of a place. See the File.media_type for more information.
   */
  files: File[];
}

/**
 * Predefined assets types that can be used to say something about the data.
 * Currently a limited list, but more may be added in the future.
 */
export enum Asset_Type {
  UNKNOWN = "UNKNOWN",
  WELL_ANNULUS_A = "WELL_ANNULUS_A",
  WELL_ANNULUS_B = "WELL_ANNULUS_B",
  WELL_ANNULUS_C = "WELL_ANNULUS_C",
  WELL_ANNULUS_D = "WELL_ANNULUS_D",
  WELL_HEAD = "WELL_HEAD",
  WELL_TUBING_HEAD = "WELL_TUBING_HEAD",
  WELL_TUBING = "WELL_TUBING",
  WELL_FLOW_LINE = "WELL_FLOW_LINE",
  WELL_CASING = "WELL_CASING",
  WELL_PRODUCTION_CASING_PRESSURE = "WELL_PRODUCTION_CASING_PRESSURE",
  WELL_INTERMITTENT_CASING_PRESSURE = "WELL_INTERMITTENT_CASING_PRESSURE",
  PIPELINE = "PIPELINE",
  PRODUCTION_LINE = "PRODUCTION_LINE",
  GAS_MANIFOLD = "GAS_MANIFOLD",
  PRODUCTION_MANIFOLD = "PRODUCTION_MANIFOLD",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function asset_TypeFromJSON(object: any): Asset_Type {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return Asset_Type.UNKNOWN;
    case 1:
    case "WELL_ANNULUS_A":
      return Asset_Type.WELL_ANNULUS_A;
    case 2:
    case "WELL_ANNULUS_B":
      return Asset_Type.WELL_ANNULUS_B;
    case 3:
    case "WELL_ANNULUS_C":
      return Asset_Type.WELL_ANNULUS_C;
    case 4:
    case "WELL_ANNULUS_D":
      return Asset_Type.WELL_ANNULUS_D;
    case 15:
    case "WELL_HEAD":
      return Asset_Type.WELL_HEAD;
    case 5:
    case "WELL_TUBING_HEAD":
      return Asset_Type.WELL_TUBING_HEAD;
    case 6:
    case "WELL_TUBING":
      return Asset_Type.WELL_TUBING;
    case 7:
    case "WELL_FLOW_LINE":
      return Asset_Type.WELL_FLOW_LINE;
    case 8:
    case "WELL_CASING":
      return Asset_Type.WELL_CASING;
    case 9:
    case "WELL_PRODUCTION_CASING_PRESSURE":
      return Asset_Type.WELL_PRODUCTION_CASING_PRESSURE;
    case 10:
    case "WELL_INTERMITTENT_CASING_PRESSURE":
      return Asset_Type.WELL_INTERMITTENT_CASING_PRESSURE;
    case 11:
    case "PIPELINE":
      return Asset_Type.PIPELINE;
    case 12:
    case "PRODUCTION_LINE":
      return Asset_Type.PRODUCTION_LINE;
    case 13:
    case "GAS_MANIFOLD":
      return Asset_Type.GAS_MANIFOLD;
    case 14:
    case "PRODUCTION_MANIFOLD":
      return Asset_Type.PRODUCTION_MANIFOLD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Asset_Type.UNRECOGNIZED;
  }
}

export function asset_TypeToNumber(object: Asset_Type): number {
  switch (object) {
    case Asset_Type.UNKNOWN:
      return 0;
    case Asset_Type.WELL_ANNULUS_A:
      return 1;
    case Asset_Type.WELL_ANNULUS_B:
      return 2;
    case Asset_Type.WELL_ANNULUS_C:
      return 3;
    case Asset_Type.WELL_ANNULUS_D:
      return 4;
    case Asset_Type.WELL_HEAD:
      return 15;
    case Asset_Type.WELL_TUBING_HEAD:
      return 5;
    case Asset_Type.WELL_TUBING:
      return 6;
    case Asset_Type.WELL_FLOW_LINE:
      return 7;
    case Asset_Type.WELL_CASING:
      return 8;
    case Asset_Type.WELL_PRODUCTION_CASING_PRESSURE:
      return 9;
    case Asset_Type.WELL_INTERMITTENT_CASING_PRESSURE:
      return 10;
    case Asset_Type.PIPELINE:
      return 11;
    case Asset_Type.PRODUCTION_LINE:
      return 12;
    case Asset_Type.GAS_MANIFOLD:
      return 13;
    case Asset_Type.PRODUCTION_MANIFOLD:
      return 14;
    case Asset_Type.UNRECOGNIZED:
    default:
      return -1;
  }
}

/**
 * A device assigned to this asset.
 * Non-operational values that the device produces will be linked to this asset
 * (i.e. pressure, but not battery level).
 */
export interface Asset_AssignedDevice {
  number: string;
  identifiers: string[];
  name?: string | undefined;
  type?: string | undefined;
  lastMessageSentAt?: Timestamp | undefined;
  lastMessageReceivedAt?: Timestamp | undefined;
  assignmentTimeRange?: TimeRange | undefined;
  health?: HealthLevel | undefined;
  numericValueTypes: Value_Numeric_Type[];
}

/** Selection object for assets. */
export interface AssetSelection {
  /** Select assets by identifier. */
  identifiers: string[];
  /** Search assets by (partial, case insensitive) identifier, name, description, notes and time zone. */
  search: string[];
  /** Select assets by type. */
  types: Asset_Type[];
  /** Select assets by tags */
  filterByTags?: TagSelection | undefined;
}

function createBaseAsset(): Asset {
  return {
    identifier: "",
    name: "",
    type: undefined,
    description: undefined,
    notes: undefined,
    timeZone: undefined,
    expectedTransmissionRate: undefined,
    metadata: undefined,
    tags: [],
    devices: [],
    inactiveDevices: [],
    organization: "",
    location: undefined,
    files: [],
  };
}

export const Asset = {
  encode(message: Asset, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(10).string(message.identifier);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.type !== undefined) {
      writer.uint32(24).int32(asset_TypeToNumber(message.type));
    }
    if (message.description !== undefined) {
      writer.uint32(34).string(message.description);
    }
    if (message.notes !== undefined) {
      writer.uint32(42).string(message.notes);
    }
    if (message.timeZone !== undefined) {
      writer.uint32(50).string(message.timeZone);
    }
    if (message.expectedTransmissionRate !== undefined) {
      Value_Numeric_Rate.encode(message.expectedTransmissionRate, writer.uint32(58).fork()).ldelim();
    }
    if (message.metadata !== undefined) {
      Struct.encode(Struct.wrap(message.metadata), writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.devices) {
      Asset_AssignedDevice.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.inactiveDevices) {
      Asset_AssignedDevice.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    if (message.organization !== "") {
      writer.uint32(90).string(message.organization);
    }
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.files) {
      File.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Asset {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = asset_TypeFromJSON(reader.int32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.notes = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.timeZone = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.expectedTransmissionRate = Value_Numeric_Rate.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.metadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.devices.push(Asset_AssignedDevice.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.inactiveDevices.push(Asset_AssignedDevice.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.files.push(File.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Asset>): Asset {
    return Asset.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Asset>): Asset {
    const message = createBaseAsset();
    message.identifier = object.identifier ?? "";
    message.name = object.name ?? "";
    message.type = object.type ?? undefined;
    message.description = object.description ?? undefined;
    message.notes = object.notes ?? undefined;
    message.timeZone = object.timeZone ?? undefined;
    message.expectedTransmissionRate =
      (object.expectedTransmissionRate !== undefined && object.expectedTransmissionRate !== null)
        ? Value_Numeric_Rate.fromPartial(object.expectedTransmissionRate)
        : undefined;
    message.metadata = object.metadata ?? undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.devices = object.devices?.map((e) => Asset_AssignedDevice.fromPartial(e)) || [];
    message.inactiveDevices = object.inactiveDevices?.map((e) => Asset_AssignedDevice.fromPartial(e)) || [];
    message.organization = object.organization ?? "";
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    message.files = object.files?.map((e) => File.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAsset_AssignedDevice(): Asset_AssignedDevice {
  return {
    number: "",
    identifiers: [],
    name: undefined,
    type: undefined,
    lastMessageSentAt: undefined,
    lastMessageReceivedAt: undefined,
    assignmentTimeRange: undefined,
    health: undefined,
    numericValueTypes: [],
  };
}

export const Asset_AssignedDevice = {
  encode(message: Asset_AssignedDevice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.number !== "") {
      writer.uint32(10).string(message.number);
    }
    for (const v of message.identifiers) {
      writer.uint32(18).string(v!);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.type !== undefined) {
      writer.uint32(34).string(message.type);
    }
    if (message.lastMessageSentAt !== undefined) {
      Timestamp.encode(message.lastMessageSentAt, writer.uint32(42).fork()).ldelim();
    }
    if (message.lastMessageReceivedAt !== undefined) {
      Timestamp.encode(message.lastMessageReceivedAt, writer.uint32(50).fork()).ldelim();
    }
    if (message.assignmentTimeRange !== undefined) {
      TimeRange.encode(message.assignmentTimeRange, writer.uint32(58).fork()).ldelim();
    }
    if (message.health !== undefined) {
      HealthLevel.encode(message.health, writer.uint32(66).fork()).ldelim();
    }
    writer.uint32(74).fork();
    for (const v of message.numericValueTypes) {
      writer.int32(value_Numeric_TypeToNumber(v));
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Asset_AssignedDevice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAsset_AssignedDevice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.number = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.identifiers.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.type = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lastMessageSentAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.lastMessageReceivedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.assignmentTimeRange = TimeRange.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.health = HealthLevel.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag === 72) {
            message.numericValueTypes.push(value_Numeric_TypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 74) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.numericValueTypes.push(value_Numeric_TypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Asset_AssignedDevice>): Asset_AssignedDevice {
    return Asset_AssignedDevice.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Asset_AssignedDevice>): Asset_AssignedDevice {
    const message = createBaseAsset_AssignedDevice();
    message.number = object.number ?? "";
    message.identifiers = object.identifiers?.map((e) => e) || [];
    message.name = object.name ?? undefined;
    message.type = object.type ?? undefined;
    message.lastMessageSentAt = (object.lastMessageSentAt !== undefined && object.lastMessageSentAt !== null)
      ? Timestamp.fromPartial(object.lastMessageSentAt)
      : undefined;
    message.lastMessageReceivedAt =
      (object.lastMessageReceivedAt !== undefined && object.lastMessageReceivedAt !== null)
        ? Timestamp.fromPartial(object.lastMessageReceivedAt)
        : undefined;
    message.assignmentTimeRange = (object.assignmentTimeRange !== undefined && object.assignmentTimeRange !== null)
      ? TimeRange.fromPartial(object.assignmentTimeRange)
      : undefined;
    message.health = (object.health !== undefined && object.health !== null)
      ? HealthLevel.fromPartial(object.health)
      : undefined;
    message.numericValueTypes = object.numericValueTypes?.map((e) => e) || [];
    return message;
  },
};

function createBaseAssetSelection(): AssetSelection {
  return { identifiers: [], search: [], types: [], filterByTags: undefined };
}

export const AssetSelection = {
  encode(message: AssetSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.identifiers) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.search) {
      writer.uint32(18).string(v!);
    }
    writer.uint32(26).fork();
    for (const v of message.types) {
      writer.int32(asset_TypeToNumber(v));
    }
    writer.ldelim();
    if (message.filterByTags !== undefined) {
      TagSelection.encode(message.filterByTags, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssetSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identifiers.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.search.push(reader.string());
          continue;
        case 3:
          if (tag === 24) {
            message.types.push(asset_TypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.types.push(asset_TypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.filterByTags = TagSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssetSelection>): AssetSelection {
    return AssetSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssetSelection>): AssetSelection {
    const message = createBaseAssetSelection();
    message.identifiers = object.identifiers?.map((e) => e) || [];
    message.search = object.search?.map((e) => e) || [];
    message.types = object.types?.map((e) => e) || [];
    message.filterByTags = (object.filterByTags !== undefined && object.filterByTags !== null)
      ? TagSelection.fromPartial(object.filterByTags)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
