import { useWindowFocus } from '@vueuse/core';
import { onBeforeUnmount, ref, watch } from 'vue';
import { onlineManager } from '@tanstack/vue-query';

export default function useSwitchOffline(): void {
  const focused = useWindowFocus();
  const checkWindowFocusTimer = ref();

  watch(focused, () => {
    if (focused.value) {
      clearTimeout(checkWindowFocusTimer.value);
      onlineManager.setOnline(true);
    } else {
      // set queries to offline after 10 minutes of window inactivity
      checkWindowFocusTimer.value = setTimeout(() => {
        if (!focused.value) {
          onlineManager.setOnline(false);
        }
      }, 10 * 60 * 1000);
    }
  });

  onBeforeUnmount(() => {
    if (checkWindowFocusTimer.value) {
      clearTimeout(checkWindowFocusTimer.value);
    }
  });
}
