import { defineStore } from 'pinia';
import { Modem } from 'hiber-grpc/src/customer_compiled/modem';

export const useDeviceMasterDetails = defineStore('deviceMasterDetails', {
  state: () =>
    ({
      _selectedItem: null,
    } as { _selectedItem: Modem | null }),
  actions: {
    setSelectedItem(item: Modem | null) {
      this._selectedItem = item;
    },
  },
  getters: {
    selectedItem(state) {
      return state._selectedItem;
    },
  },
});
