// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.19.1
// source: asset_service.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Asset, Asset_Type, asset_TypeFromJSON, asset_TypeToNumber, AssetSelection } from "./asset";
import { Filter_ChildOrganizations, Location, Pagination, Pagination_Result, Timestamp } from "./base";
import { DeviceSelection } from "./device";
import { File } from "./file";
import { Struct, Value } from "./google/protobuf/struct";
import { TagSelection } from "./tag";
import { Value_Numeric_Rate } from "./value";

export const protobufPackage = "hiber.asset";

/** List the assets in your (selected) organization. */
export interface ListAsset {
}

export enum ListAsset_Sort {
  /** NAME_ASC - Sort alphabetically on the name of the asset, in ascending order. Default. */
  NAME_ASC = "NAME_ASC",
  /** NAME_DESC - Sort alphabetically on the name of the asset, in descending order. */
  NAME_DESC = "NAME_DESC",
  /** ACTIVITY - Sort assets on most recent activity first (e.g. newest value received). */
  ACTIVITY = "ACTIVITY",
  /** INACTIVITY - Sort assets on least recent activity first (e.g. longest no value received). */
  INACTIVITY = "INACTIVITY",
  /** TYPE_ASC - Sort alphabetically on the asset type, in ascending order. */
  TYPE_ASC = "TYPE_ASC",
  /** TYPE_DESC - Sort alphabetically on the asset type, in descending order. */
  TYPE_DESC = "TYPE_DESC",
  /** TAG_TYPE_WELL_ASC - Sort alphabetically on any tags of type 'well', in ascending order. */
  TAG_TYPE_WELL_ASC = "TAG_TYPE_WELL_ASC",
  /** TAG_TYPE_WELL_DESC - Sort alphabetically on any tags of type 'well', in descending order. */
  TAG_TYPE_WELL_DESC = "TAG_TYPE_WELL_DESC",
  /** TAG_TYPE_SITE_ASC - Sort alphabetically on any tags of type 'site', in ascending order. */
  TAG_TYPE_SITE_ASC = "TAG_TYPE_SITE_ASC",
  /** TAG_TYPE_SITE_DESC - Sort alphabetically on any tags of type 'site', in descending order. */
  TAG_TYPE_SITE_DESC = "TAG_TYPE_SITE_DESC",
  /** TAG_TYPE_PRODUCTION_AREA_ASC - Sort alphabetically on any tags of type 'production_area', in ascending order. */
  TAG_TYPE_PRODUCTION_AREA_ASC = "TAG_TYPE_PRODUCTION_AREA_ASC",
  /** TAG_TYPE_PRODUCTION_AREA_DESC - Sort alphabetically on any tags of type 'production_area', in descending order. */
  TAG_TYPE_PRODUCTION_AREA_DESC = "TAG_TYPE_PRODUCTION_AREA_DESC",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function listAsset_SortFromJSON(object: any): ListAsset_Sort {
  switch (object) {
    case 0:
    case "NAME_ASC":
      return ListAsset_Sort.NAME_ASC;
    case 1:
    case "NAME_DESC":
      return ListAsset_Sort.NAME_DESC;
    case 2:
    case "ACTIVITY":
      return ListAsset_Sort.ACTIVITY;
    case 3:
    case "INACTIVITY":
      return ListAsset_Sort.INACTIVITY;
    case 4:
    case "TYPE_ASC":
      return ListAsset_Sort.TYPE_ASC;
    case 5:
    case "TYPE_DESC":
      return ListAsset_Sort.TYPE_DESC;
    case 6:
    case "TAG_TYPE_WELL_ASC":
      return ListAsset_Sort.TAG_TYPE_WELL_ASC;
    case 7:
    case "TAG_TYPE_WELL_DESC":
      return ListAsset_Sort.TAG_TYPE_WELL_DESC;
    case 8:
    case "TAG_TYPE_SITE_ASC":
      return ListAsset_Sort.TAG_TYPE_SITE_ASC;
    case 9:
    case "TAG_TYPE_SITE_DESC":
      return ListAsset_Sort.TAG_TYPE_SITE_DESC;
    case 10:
    case "TAG_TYPE_PRODUCTION_AREA_ASC":
      return ListAsset_Sort.TAG_TYPE_PRODUCTION_AREA_ASC;
    case 11:
    case "TAG_TYPE_PRODUCTION_AREA_DESC":
      return ListAsset_Sort.TAG_TYPE_PRODUCTION_AREA_DESC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ListAsset_Sort.UNRECOGNIZED;
  }
}

export function listAsset_SortToNumber(object: ListAsset_Sort): number {
  switch (object) {
    case ListAsset_Sort.NAME_ASC:
      return 0;
    case ListAsset_Sort.NAME_DESC:
      return 1;
    case ListAsset_Sort.ACTIVITY:
      return 2;
    case ListAsset_Sort.INACTIVITY:
      return 3;
    case ListAsset_Sort.TYPE_ASC:
      return 4;
    case ListAsset_Sort.TYPE_DESC:
      return 5;
    case ListAsset_Sort.TAG_TYPE_WELL_ASC:
      return 6;
    case ListAsset_Sort.TAG_TYPE_WELL_DESC:
      return 7;
    case ListAsset_Sort.TAG_TYPE_SITE_ASC:
      return 8;
    case ListAsset_Sort.TAG_TYPE_SITE_DESC:
      return 9;
    case ListAsset_Sort.TAG_TYPE_PRODUCTION_AREA_ASC:
      return 10;
    case ListAsset_Sort.TAG_TYPE_PRODUCTION_AREA_DESC:
      return 11;
    case ListAsset_Sort.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface ListAsset_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /**
   * Select which assets to return.
   * Optional, when omitted or empty everything is included.
   */
  selection?:
    | AssetSelection
    | undefined;
  /** Paginate through results. */
  pagination?:
    | Pagination
    | undefined;
  /** Sort the assets with the given sort options. */
  sortBy: ListAsset_Sort[];
  /** Include assets from the selected child organizations. */
  childOrganizations?: Filter_ChildOrganizations | undefined;
}

export interface ListAsset_Response {
  assets: Asset[];
  pagination: Pagination_Result | undefined;
  request: ListAsset_Request | undefined;
}

/** Create one or more assets in your (selected) organization. */
export interface CreateAsset {
  name?: string | undefined;
  type?: Asset_Type | undefined;
  description?: string | undefined;
  notes?: string | undefined;
  timeZone?: string | undefined;
  expectedTransmissionRate?: Value_Numeric_Rate | undefined;
  metadata?: { [key: string]: any } | undefined;
  location?: Location | undefined;
  files: File[];
}

export interface CreateAsset_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Assets to create. */
  create: CreateAsset[];
}

export interface CreateAsset_Response {
  created: Asset[];
  request: CreateAsset_Request | undefined;
}

/** Update one or more assets in your (selected) organization. */
export interface UpdateAsset {
  updateName?: string | undefined;
  updateType?: Asset_Type | undefined;
  updateDescription?: string | undefined;
  updateNotes?: string | undefined;
  updateTimeZone?: string | undefined;
  updateExpectedTransmissionRate?: Value_Numeric_Rate | undefined;
  addMetadata: { [key: string]: any | undefined };
  addMetadataText: { [key: string]: string };
  removeMetadataKeys: string[];
  location?: Location | undefined;
  removeLocation?: boolean | undefined;
  addFiles: File[];
  deleteFiles: string[];
}

export interface UpdateAsset_AddMetadataEntry {
  key: string;
  value: any | undefined;
}

export interface UpdateAsset_AddMetadataTextEntry {
  key: string;
  value: string;
}

export interface UpdateAsset_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select which assets to update. Not optional and cannot be empty. */
  selection:
    | AssetSelection
    | undefined;
  /** The update to apply to the selected assets. */
  update:
    | UpdateAsset
    | undefined;
  /**
   * Paginate through results (for the Response, not the actual update).
   * To get the next pages, use the List call with the same selection and pagination for the next page.
   */
  pagination?: Pagination | undefined;
}

export interface UpdateAsset_Response {
  assets: Asset[];
  pagination: Pagination_Result | undefined;
  request: UpdateAsset_Request | undefined;
}

/** Delete assets in your (selected) organization. */
export interface DeleteAsset {
}

export interface DeleteAsset_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select which assets to delete. Not optional and cannot be empty. */
  selection: AssetSelection | undefined;
}

export interface DeleteAsset_Response {
  deleted: number;
  request: DeleteAsset_Request | undefined;
}

/** Change the assigned tags for a selection of assets in your (selected) organization. */
export interface AssignAssetTags {
}

export interface AssignAssetTags_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select which assets to assign. Not optional and cannot be empty. */
  selection:
    | AssetSelection
    | undefined;
  /** Select which tags to assign this asset to. */
  tags:
    | TagSelection
    | undefined;
  /**
   * Paginate through results (for the Response, not the actual update).
   * To get the next pages, use the List call with the same selection and pagination for the next page.
   */
  pagination?: Pagination | undefined;
}

export interface AssignAssetTags_Response {
  assets: Asset[];
  pagination: Pagination_Result | undefined;
  request: AssignAssetTags_Request | undefined;
}

/** Change the assigned tags for a selection of assets in your (selected) organization. */
export interface UnassignAssetTags {
}

export interface UnassignAssetTags_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select which assets to unassign. Not optional and cannot be empty. */
  selection:
    | AssetSelection
    | undefined;
  /** Select which tags to unassign this asset from. */
  tags:
    | TagSelection
    | undefined;
  /**
   * Paginate through results (for the Response, not the actual update).
   * To get the next pages, use the List call with the same selection and pagination for the next page.
   */
  pagination?: Pagination | undefined;
}

export interface UnassignAssetTags_Response {
  assets: Asset[];
  pagination: Pagination_Result | undefined;
  request: UnassignAssetTags_Request | undefined;
}

/** Change the assigned devices for a selection of assets in your (selected) organization. */
export interface AssignAssetDevices {
}

export interface AssignAssetDevices_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select which assets to assign. Not optional and cannot be empty. */
  selection:
    | AssetSelection
    | undefined;
  /** Select which devices to assign this asset to. */
  devices:
    | DeviceSelection
    | undefined;
  /**
   * Paginate through results (for the Response, not the actual update).
   * To get the next pages, use the List call with the same selection and pagination for the next page.
   */
  pagination?:
    | Pagination
    | undefined;
  /**
   * Time that the assignment should be active.
   * This sets the assignment to start in the past, but would not have effect in the past for assignments like
   * parsers and alarms (they will only be triggered for new messages / values).
   * It would however work for assets having access to device data.
   * This is not allowed to be a value in the future at the moment.
   */
  overrideTime?:
    | Timestamp
    | undefined;
  /**
   * Time that the assignment ended.
   * This marks the assignment as ended at the given moment in the past, but would not have effect in the past
   * for assignments like parsers and alarms (i.e. no alarm events are removed).
   * It would however work for assets having access to device data.
   * This is not allowed to be a value in the future at the moment.
   */
  endTime?: Timestamp | undefined;
}

export interface AssignAssetDevices_Response {
  assets: Asset[];
  pagination: Pagination_Result | undefined;
  request: AssignAssetDevices_Request | undefined;
}

/** Change the assigned devices for a selection of assets in your (selected) organization. */
export interface UnassignAssetDevices {
}

export interface UnassignAssetDevices_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select which assets to unassign. Not optional and cannot be empty. */
  selection:
    | AssetSelection
    | undefined;
  /** Select which devices to unassign this asset from. */
  devices:
    | DeviceSelection
    | undefined;
  /**
   * Paginate through results (for the Response, not the actual update).
   * To get the next pages, use the List call with the same selection and pagination for the next page.
   */
  pagination?:
    | Pagination
    | undefined;
  /**
   * Time that the assignment ended.
   * This marks the assignment as ended at the given moment in the past, but would not have effect in the past
   * for assignments like parsers and alarms (i.e. no alarm events are removed).
   * It would however work for assets having access to device data.
   * This is not allowed to be a value in the future at the moment.
   */
  overrideTime?: Timestamp | undefined;
}

export interface UnassignAssetDevices_Response {
  assets: Asset[];
  pagination: Pagination_Result | undefined;
  request: UnassignAssetDevices_Request | undefined;
}

function createBaseListAsset(): ListAsset {
  return {};
}

export const ListAsset = {
  encode(_: ListAsset, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAsset {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListAsset>): ListAsset {
    return ListAsset.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListAsset>): ListAsset {
    const message = createBaseListAsset();
    return message;
  },
};

function createBaseListAsset_Request(): ListAsset_Request {
  return {
    organization: undefined,
    selection: undefined,
    pagination: undefined,
    sortBy: [],
    childOrganizations: undefined,
  };
}

export const ListAsset_Request = {
  encode(message: ListAsset_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      AssetSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).fork();
    for (const v of message.sortBy) {
      writer.int32(listAsset_SortToNumber(v));
    }
    writer.ldelim();
    if (message.childOrganizations !== undefined) {
      Filter_ChildOrganizations.encode(message.childOrganizations, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAsset_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAsset_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = AssetSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag === 32) {
            message.sortBy.push(listAsset_SortFromJSON(reader.int32()));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sortBy.push(listAsset_SortFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.childOrganizations = Filter_ChildOrganizations.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListAsset_Request>): ListAsset_Request {
    return ListAsset_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListAsset_Request>): ListAsset_Request {
    const message = createBaseListAsset_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? AssetSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.sortBy = object.sortBy?.map((e) => e) || [];
    message.childOrganizations = (object.childOrganizations !== undefined && object.childOrganizations !== null)
      ? Filter_ChildOrganizations.fromPartial(object.childOrganizations)
      : undefined;
    return message;
  },
};

function createBaseListAsset_Response(): ListAsset_Response {
  return { assets: [], pagination: undefined, request: undefined };
}

export const ListAsset_Response = {
  encode(message: ListAsset_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assets) {
      Asset.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListAsset_Request.encode(message.request, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAsset_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAsset_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assets.push(Asset.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.request = ListAsset_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListAsset_Response>): ListAsset_Response {
    return ListAsset_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListAsset_Response>): ListAsset_Response {
    const message = createBaseListAsset_Response();
    message.assets = object.assets?.map((e) => Asset.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    message.request = (object.request !== undefined && object.request !== null)
      ? ListAsset_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseCreateAsset(): CreateAsset {
  return {
    name: undefined,
    type: undefined,
    description: undefined,
    notes: undefined,
    timeZone: undefined,
    expectedTransmissionRate: undefined,
    metadata: undefined,
    location: undefined,
    files: [],
  };
}

export const CreateAsset = {
  encode(message: CreateAsset, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.type !== undefined) {
      writer.uint32(16).int32(asset_TypeToNumber(message.type));
    }
    if (message.description !== undefined) {
      writer.uint32(26).string(message.description);
    }
    if (message.notes !== undefined) {
      writer.uint32(34).string(message.notes);
    }
    if (message.timeZone !== undefined) {
      writer.uint32(42).string(message.timeZone);
    }
    if (message.expectedTransmissionRate !== undefined) {
      Value_Numeric_Rate.encode(message.expectedTransmissionRate, writer.uint32(50).fork()).ldelim();
    }
    if (message.metadata !== undefined) {
      Struct.encode(Struct.wrap(message.metadata), writer.uint32(58).fork()).ldelim();
    }
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.files) {
      File.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAsset {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = asset_TypeFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.notes = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.timeZone = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.expectedTransmissionRate = Value_Numeric_Rate.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.files.push(File.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateAsset>): CreateAsset {
    return CreateAsset.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateAsset>): CreateAsset {
    const message = createBaseCreateAsset();
    message.name = object.name ?? undefined;
    message.type = object.type ?? undefined;
    message.description = object.description ?? undefined;
    message.notes = object.notes ?? undefined;
    message.timeZone = object.timeZone ?? undefined;
    message.expectedTransmissionRate =
      (object.expectedTransmissionRate !== undefined && object.expectedTransmissionRate !== null)
        ? Value_Numeric_Rate.fromPartial(object.expectedTransmissionRate)
        : undefined;
    message.metadata = object.metadata ?? undefined;
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    message.files = object.files?.map((e) => File.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateAsset_Request(): CreateAsset_Request {
  return { organization: undefined, create: [] };
}

export const CreateAsset_Request = {
  encode(message: CreateAsset_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    for (const v of message.create) {
      CreateAsset.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAsset_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAsset_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.create.push(CreateAsset.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateAsset_Request>): CreateAsset_Request {
    return CreateAsset_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateAsset_Request>): CreateAsset_Request {
    const message = createBaseCreateAsset_Request();
    message.organization = object.organization ?? undefined;
    message.create = object.create?.map((e) => CreateAsset.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateAsset_Response(): CreateAsset_Response {
  return { created: [], request: undefined };
}

export const CreateAsset_Response = {
  encode(message: CreateAsset_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.created) {
      Asset.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      CreateAsset_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAsset_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAsset_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.created.push(Asset.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = CreateAsset_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateAsset_Response>): CreateAsset_Response {
    return CreateAsset_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateAsset_Response>): CreateAsset_Response {
    const message = createBaseCreateAsset_Response();
    message.created = object.created?.map((e) => Asset.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? CreateAsset_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseUpdateAsset(): UpdateAsset {
  return {
    updateName: undefined,
    updateType: undefined,
    updateDescription: undefined,
    updateNotes: undefined,
    updateTimeZone: undefined,
    updateExpectedTransmissionRate: undefined,
    addMetadata: {},
    addMetadataText: {},
    removeMetadataKeys: [],
    location: undefined,
    removeLocation: undefined,
    addFiles: [],
    deleteFiles: [],
  };
}

export const UpdateAsset = {
  encode(message: UpdateAsset, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updateName !== undefined) {
      writer.uint32(10).string(message.updateName);
    }
    if (message.updateType !== undefined) {
      writer.uint32(16).int32(asset_TypeToNumber(message.updateType));
    }
    if (message.updateDescription !== undefined) {
      writer.uint32(26).string(message.updateDescription);
    }
    if (message.updateNotes !== undefined) {
      writer.uint32(34).string(message.updateNotes);
    }
    if (message.updateTimeZone !== undefined) {
      writer.uint32(42).string(message.updateTimeZone);
    }
    if (message.updateExpectedTransmissionRate !== undefined) {
      Value_Numeric_Rate.encode(message.updateExpectedTransmissionRate, writer.uint32(50).fork()).ldelim();
    }
    Object.entries(message.addMetadata).forEach(([key, value]) => {
      if (value !== undefined) {
        UpdateAsset_AddMetadataEntry.encode({ key: key as any, value }, writer.uint32(58).fork()).ldelim();
      }
    });
    Object.entries(message.addMetadataText).forEach(([key, value]) => {
      UpdateAsset_AddMetadataTextEntry.encode({ key: key as any, value }, writer.uint32(66).fork()).ldelim();
    });
    for (const v of message.removeMetadataKeys) {
      writer.uint32(74).string(v!);
    }
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(82).fork()).ldelim();
    }
    if (message.removeLocation !== undefined) {
      writer.uint32(88).bool(message.removeLocation);
    }
    for (const v of message.addFiles) {
      File.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.deleteFiles) {
      writer.uint32(106).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAsset {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.updateName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.updateType = asset_TypeFromJSON(reader.int32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updateDescription = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updateNotes = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.updateTimeZone = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updateExpectedTransmissionRate = Value_Numeric_Rate.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          const entry7 = UpdateAsset_AddMetadataEntry.decode(reader, reader.uint32());
          if (entry7.value !== undefined) {
            message.addMetadata[entry7.key] = entry7.value;
          }
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          const entry8 = UpdateAsset_AddMetadataTextEntry.decode(reader, reader.uint32());
          if (entry8.value !== undefined) {
            message.addMetadataText[entry8.key] = entry8.value;
          }
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.removeMetadataKeys.push(reader.string());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.removeLocation = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.addFiles.push(File.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.deleteFiles.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateAsset>): UpdateAsset {
    return UpdateAsset.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateAsset>): UpdateAsset {
    const message = createBaseUpdateAsset();
    message.updateName = object.updateName ?? undefined;
    message.updateType = object.updateType ?? undefined;
    message.updateDescription = object.updateDescription ?? undefined;
    message.updateNotes = object.updateNotes ?? undefined;
    message.updateTimeZone = object.updateTimeZone ?? undefined;
    message.updateExpectedTransmissionRate =
      (object.updateExpectedTransmissionRate !== undefined && object.updateExpectedTransmissionRate !== null)
        ? Value_Numeric_Rate.fromPartial(object.updateExpectedTransmissionRate)
        : undefined;
    message.addMetadata = Object.entries(object.addMetadata ?? {}).reduce<{ [key: string]: any | undefined }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = value;
        }
        return acc;
      },
      {},
    );
    message.addMetadataText = Object.entries(object.addMetadataText ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.removeMetadataKeys = object.removeMetadataKeys?.map((e) => e) || [];
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    message.removeLocation = object.removeLocation ?? undefined;
    message.addFiles = object.addFiles?.map((e) => File.fromPartial(e)) || [];
    message.deleteFiles = object.deleteFiles?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateAsset_AddMetadataEntry(): UpdateAsset_AddMetadataEntry {
  return { key: "", value: undefined };
}

export const UpdateAsset_AddMetadataEntry = {
  encode(message: UpdateAsset_AddMetadataEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Value.encode(Value.wrap(message.value), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAsset_AddMetadataEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAsset_AddMetadataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Value.unwrap(Value.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateAsset_AddMetadataEntry>): UpdateAsset_AddMetadataEntry {
    return UpdateAsset_AddMetadataEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateAsset_AddMetadataEntry>): UpdateAsset_AddMetadataEntry {
    const message = createBaseUpdateAsset_AddMetadataEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? undefined;
    return message;
  },
};

function createBaseUpdateAsset_AddMetadataTextEntry(): UpdateAsset_AddMetadataTextEntry {
  return { key: "", value: "" };
}

export const UpdateAsset_AddMetadataTextEntry = {
  encode(message: UpdateAsset_AddMetadataTextEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAsset_AddMetadataTextEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAsset_AddMetadataTextEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateAsset_AddMetadataTextEntry>): UpdateAsset_AddMetadataTextEntry {
    return UpdateAsset_AddMetadataTextEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateAsset_AddMetadataTextEntry>): UpdateAsset_AddMetadataTextEntry {
    const message = createBaseUpdateAsset_AddMetadataTextEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseUpdateAsset_Request(): UpdateAsset_Request {
  return { organization: undefined, selection: undefined, update: undefined, pagination: undefined };
}

export const UpdateAsset_Request = {
  encode(message: UpdateAsset_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      AssetSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.update !== undefined) {
      UpdateAsset.encode(message.update, writer.uint32(26).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAsset_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAsset_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = AssetSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.update = UpdateAsset.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateAsset_Request>): UpdateAsset_Request {
    return UpdateAsset_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateAsset_Request>): UpdateAsset_Request {
    const message = createBaseUpdateAsset_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? AssetSelection.fromPartial(object.selection)
      : undefined;
    message.update = (object.update !== undefined && object.update !== null)
      ? UpdateAsset.fromPartial(object.update)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseUpdateAsset_Response(): UpdateAsset_Response {
  return { assets: [], pagination: undefined, request: undefined };
}

export const UpdateAsset_Response = {
  encode(message: UpdateAsset_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assets) {
      Asset.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    if (message.request !== undefined) {
      UpdateAsset_Request.encode(message.request, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAsset_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAsset_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assets.push(Asset.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.request = UpdateAsset_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateAsset_Response>): UpdateAsset_Response {
    return UpdateAsset_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateAsset_Response>): UpdateAsset_Response {
    const message = createBaseUpdateAsset_Response();
    message.assets = object.assets?.map((e) => Asset.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    message.request = (object.request !== undefined && object.request !== null)
      ? UpdateAsset_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseDeleteAsset(): DeleteAsset {
  return {};
}

export const DeleteAsset = {
  encode(_: DeleteAsset, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAsset {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteAsset>): DeleteAsset {
    return DeleteAsset.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<DeleteAsset>): DeleteAsset {
    const message = createBaseDeleteAsset();
    return message;
  },
};

function createBaseDeleteAsset_Request(): DeleteAsset_Request {
  return { organization: undefined, selection: undefined };
}

export const DeleteAsset_Request = {
  encode(message: DeleteAsset_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      AssetSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAsset_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAsset_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = AssetSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteAsset_Request>): DeleteAsset_Request {
    return DeleteAsset_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteAsset_Request>): DeleteAsset_Request {
    const message = createBaseDeleteAsset_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? AssetSelection.fromPartial(object.selection)
      : undefined;
    return message;
  },
};

function createBaseDeleteAsset_Response(): DeleteAsset_Response {
  return { deleted: 0, request: undefined };
}

export const DeleteAsset_Response = {
  encode(message: DeleteAsset_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deleted !== 0) {
      writer.uint32(8).uint32(message.deleted);
    }
    if (message.request !== undefined) {
      DeleteAsset_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAsset_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAsset_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deleted = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = DeleteAsset_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteAsset_Response>): DeleteAsset_Response {
    return DeleteAsset_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteAsset_Response>): DeleteAsset_Response {
    const message = createBaseDeleteAsset_Response();
    message.deleted = object.deleted ?? 0;
    message.request = (object.request !== undefined && object.request !== null)
      ? DeleteAsset_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseAssignAssetTags(): AssignAssetTags {
  return {};
}

export const AssignAssetTags = {
  encode(_: AssignAssetTags, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignAssetTags {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignAssetTags();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignAssetTags>): AssignAssetTags {
    return AssignAssetTags.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<AssignAssetTags>): AssignAssetTags {
    const message = createBaseAssignAssetTags();
    return message;
  },
};

function createBaseAssignAssetTags_Request(): AssignAssetTags_Request {
  return { organization: undefined, selection: undefined, tags: undefined, pagination: undefined };
}

export const AssignAssetTags_Request = {
  encode(message: AssignAssetTags_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      AssetSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.tags !== undefined) {
      TagSelection.encode(message.tags, writer.uint32(26).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignAssetTags_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignAssetTags_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = AssetSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tags = TagSelection.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignAssetTags_Request>): AssignAssetTags_Request {
    return AssignAssetTags_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssignAssetTags_Request>): AssignAssetTags_Request {
    const message = createBaseAssignAssetTags_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? AssetSelection.fromPartial(object.selection)
      : undefined;
    message.tags = (object.tags !== undefined && object.tags !== null)
      ? TagSelection.fromPartial(object.tags)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseAssignAssetTags_Response(): AssignAssetTags_Response {
  return { assets: [], pagination: undefined, request: undefined };
}

export const AssignAssetTags_Response = {
  encode(message: AssignAssetTags_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assets) {
      Asset.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    if (message.request !== undefined) {
      AssignAssetTags_Request.encode(message.request, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignAssetTags_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignAssetTags_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assets.push(Asset.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.request = AssignAssetTags_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignAssetTags_Response>): AssignAssetTags_Response {
    return AssignAssetTags_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssignAssetTags_Response>): AssignAssetTags_Response {
    const message = createBaseAssignAssetTags_Response();
    message.assets = object.assets?.map((e) => Asset.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    message.request = (object.request !== undefined && object.request !== null)
      ? AssignAssetTags_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseUnassignAssetTags(): UnassignAssetTags {
  return {};
}

export const UnassignAssetTags = {
  encode(_: UnassignAssetTags, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnassignAssetTags {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnassignAssetTags();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnassignAssetTags>): UnassignAssetTags {
    return UnassignAssetTags.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UnassignAssetTags>): UnassignAssetTags {
    const message = createBaseUnassignAssetTags();
    return message;
  },
};

function createBaseUnassignAssetTags_Request(): UnassignAssetTags_Request {
  return { organization: undefined, selection: undefined, tags: undefined, pagination: undefined };
}

export const UnassignAssetTags_Request = {
  encode(message: UnassignAssetTags_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      AssetSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.tags !== undefined) {
      TagSelection.encode(message.tags, writer.uint32(26).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnassignAssetTags_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnassignAssetTags_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = AssetSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tags = TagSelection.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnassignAssetTags_Request>): UnassignAssetTags_Request {
    return UnassignAssetTags_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnassignAssetTags_Request>): UnassignAssetTags_Request {
    const message = createBaseUnassignAssetTags_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? AssetSelection.fromPartial(object.selection)
      : undefined;
    message.tags = (object.tags !== undefined && object.tags !== null)
      ? TagSelection.fromPartial(object.tags)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseUnassignAssetTags_Response(): UnassignAssetTags_Response {
  return { assets: [], pagination: undefined, request: undefined };
}

export const UnassignAssetTags_Response = {
  encode(message: UnassignAssetTags_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assets) {
      Asset.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    if (message.request !== undefined) {
      UnassignAssetTags_Request.encode(message.request, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnassignAssetTags_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnassignAssetTags_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assets.push(Asset.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.request = UnassignAssetTags_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnassignAssetTags_Response>): UnassignAssetTags_Response {
    return UnassignAssetTags_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnassignAssetTags_Response>): UnassignAssetTags_Response {
    const message = createBaseUnassignAssetTags_Response();
    message.assets = object.assets?.map((e) => Asset.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    message.request = (object.request !== undefined && object.request !== null)
      ? UnassignAssetTags_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseAssignAssetDevices(): AssignAssetDevices {
  return {};
}

export const AssignAssetDevices = {
  encode(_: AssignAssetDevices, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignAssetDevices {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignAssetDevices();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignAssetDevices>): AssignAssetDevices {
    return AssignAssetDevices.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<AssignAssetDevices>): AssignAssetDevices {
    const message = createBaseAssignAssetDevices();
    return message;
  },
};

function createBaseAssignAssetDevices_Request(): AssignAssetDevices_Request {
  return {
    organization: undefined,
    selection: undefined,
    devices: undefined,
    pagination: undefined,
    overrideTime: undefined,
    endTime: undefined,
  };
}

export const AssignAssetDevices_Request = {
  encode(message: AssignAssetDevices_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      AssetSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.devices !== undefined) {
      DeviceSelection.encode(message.devices, writer.uint32(26).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(34).fork()).ldelim();
    }
    if (message.overrideTime !== undefined) {
      Timestamp.encode(message.overrideTime, writer.uint32(42).fork()).ldelim();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(message.endTime, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignAssetDevices_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignAssetDevices_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = AssetSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.devices = DeviceSelection.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.overrideTime = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.endTime = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignAssetDevices_Request>): AssignAssetDevices_Request {
    return AssignAssetDevices_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssignAssetDevices_Request>): AssignAssetDevices_Request {
    const message = createBaseAssignAssetDevices_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? AssetSelection.fromPartial(object.selection)
      : undefined;
    message.devices = (object.devices !== undefined && object.devices !== null)
      ? DeviceSelection.fromPartial(object.devices)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.overrideTime = (object.overrideTime !== undefined && object.overrideTime !== null)
      ? Timestamp.fromPartial(object.overrideTime)
      : undefined;
    message.endTime = (object.endTime !== undefined && object.endTime !== null)
      ? Timestamp.fromPartial(object.endTime)
      : undefined;
    return message;
  },
};

function createBaseAssignAssetDevices_Response(): AssignAssetDevices_Response {
  return { assets: [], pagination: undefined, request: undefined };
}

export const AssignAssetDevices_Response = {
  encode(message: AssignAssetDevices_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assets) {
      Asset.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    if (message.request !== undefined) {
      AssignAssetDevices_Request.encode(message.request, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignAssetDevices_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignAssetDevices_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assets.push(Asset.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.request = AssignAssetDevices_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AssignAssetDevices_Response>): AssignAssetDevices_Response {
    return AssignAssetDevices_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssignAssetDevices_Response>): AssignAssetDevices_Response {
    const message = createBaseAssignAssetDevices_Response();
    message.assets = object.assets?.map((e) => Asset.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    message.request = (object.request !== undefined && object.request !== null)
      ? AssignAssetDevices_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseUnassignAssetDevices(): UnassignAssetDevices {
  return {};
}

export const UnassignAssetDevices = {
  encode(_: UnassignAssetDevices, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnassignAssetDevices {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnassignAssetDevices();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnassignAssetDevices>): UnassignAssetDevices {
    return UnassignAssetDevices.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UnassignAssetDevices>): UnassignAssetDevices {
    const message = createBaseUnassignAssetDevices();
    return message;
  },
};

function createBaseUnassignAssetDevices_Request(): UnassignAssetDevices_Request {
  return {
    organization: undefined,
    selection: undefined,
    devices: undefined,
    pagination: undefined,
    overrideTime: undefined,
  };
}

export const UnassignAssetDevices_Request = {
  encode(message: UnassignAssetDevices_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      AssetSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.devices !== undefined) {
      DeviceSelection.encode(message.devices, writer.uint32(26).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(34).fork()).ldelim();
    }
    if (message.overrideTime !== undefined) {
      Timestamp.encode(message.overrideTime, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnassignAssetDevices_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnassignAssetDevices_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = AssetSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.devices = DeviceSelection.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.overrideTime = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnassignAssetDevices_Request>): UnassignAssetDevices_Request {
    return UnassignAssetDevices_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnassignAssetDevices_Request>): UnassignAssetDevices_Request {
    const message = createBaseUnassignAssetDevices_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? AssetSelection.fromPartial(object.selection)
      : undefined;
    message.devices = (object.devices !== undefined && object.devices !== null)
      ? DeviceSelection.fromPartial(object.devices)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.overrideTime = (object.overrideTime !== undefined && object.overrideTime !== null)
      ? Timestamp.fromPartial(object.overrideTime)
      : undefined;
    return message;
  },
};

function createBaseUnassignAssetDevices_Response(): UnassignAssetDevices_Response {
  return { assets: [], pagination: undefined, request: undefined };
}

export const UnassignAssetDevices_Response = {
  encode(message: UnassignAssetDevices_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assets) {
      Asset.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    if (message.request !== undefined) {
      UnassignAssetDevices_Request.encode(message.request, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnassignAssetDevices_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnassignAssetDevices_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assets.push(Asset.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.request = UnassignAssetDevices_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnassignAssetDevices_Response>): UnassignAssetDevices_Response {
    return UnassignAssetDevices_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnassignAssetDevices_Response>): UnassignAssetDevices_Response {
    const message = createBaseUnassignAssetDevices_Response();
    message.assets = object.assets?.map((e) => Asset.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    message.request = (object.request !== undefined && object.request !== null)
      ? UnassignAssetDevices_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

/**
 * Manage Assets in your organization, creating new assets,
 * updating or deleting them in bulk and changing assigned tags and devices.
 */
export type AssetServiceDefinition = typeof AssetServiceDefinition;
export const AssetServiceDefinition = {
  name: "AssetService",
  fullName: "hiber.asset.AssetService",
  methods: {
    /** List the assets in your organization. */
    list: {
      name: "List",
      requestType: ListAsset_Request,
      requestStream: false,
      responseType: ListAsset_Response,
      responseStream: false,
      options: {},
    },
    /** Create assets in your organization. */
    create: {
      name: "Create",
      requestType: CreateAsset_Request,
      requestStream: false,
      responseType: CreateAsset_Response,
      responseStream: false,
      options: {},
    },
    /** Update assets in your organization. */
    update: {
      name: "Update",
      requestType: UpdateAsset_Request,
      requestStream: false,
      responseType: UpdateAsset_Response,
      responseStream: false,
      options: {},
    },
    /** Delete assets in your organization. */
    delete: {
      name: "Delete",
      requestType: DeleteAsset_Request,
      requestStream: false,
      responseType: DeleteAsset_Response,
      responseStream: false,
      options: {},
    },
    /** Assign (a) tag(s) to an asset. */
    assignTag: {
      name: "AssignTag",
      requestType: AssignAssetTags_Request,
      requestStream: false,
      responseType: AssignAssetTags_Response,
      responseStream: false,
      options: {},
    },
    /** Unassign (a) tag(s) from an asset. */
    unassignTag: {
      name: "UnassignTag",
      requestType: UnassignAssetTags_Request,
      requestStream: false,
      responseType: UnassignAssetTags_Response,
      responseStream: false,
      options: {},
    },
    /** Assign (a) device(s) to an asset. */
    assignDevice: {
      name: "AssignDevice",
      requestType: AssignAssetDevices_Request,
      requestStream: false,
      responseType: AssignAssetDevices_Response,
      responseStream: false,
      options: {},
    },
    /** Unassign (a) device(s) from an asset. */
    unassignDevice: {
      name: "UnassignDevice",
      requestType: UnassignAssetDevices_Request,
      requestStream: false,
      responseType: UnassignAssetDevices_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
