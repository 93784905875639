import { Client } from 'nice-grpc-web';
import { DeepPartial } from '../customer_compiled/field_service';
import { GrpcClientFactoryType } from './types';
import { DeleteTokenRequest_Response } from '../customer_compiled/token';
import {
  CreateMQTTPublisherRequest,
  DeleteMQTTPublisherRequest,
  DisableMQTTPublisherRequest,
  DisableMQTTPublisherRequest_Response,
  EnableMQTTPublisherRequest,
  EnableMQTTPublisherRequest_Response,
  ListMQTTPublishersRequest,
  ListMQTTPublishersRequest_Response,
  MQTTPublisher,
  MQTTServiceDefinition,
  UpdateMQTTPublisherRequest
} from '../customer_compiled/integration_mqtt';

export default class MqttService {
  private static instance: MqttService;
  private readonly client: Client<typeof MQTTServiceDefinition>;

  private constructor(grpcClientFactory: GrpcClientFactoryType) {
    this.client = grpcClientFactory<typeof MQTTServiceDefinition>(
      MQTTServiceDefinition,
    );
  }

  static getInstance(grpcClientFactory: GrpcClientFactoryType) {
    if (!MqttService.instance) {
      MqttService.instance = new MqttService(grpcClientFactory);
    }

    return MqttService.instance;
  }

  list(payload: DeepPartial<ListMQTTPublishersRequest>): Promise<ListMQTTPublishersRequest_Response> {
    const request = ListMQTTPublishersRequest.fromPartial(payload);
    return this.client.list(request);
  }

  create(payload: DeepPartial<CreateMQTTPublisherRequest>): Promise<MQTTPublisher> {
    const request = CreateMQTTPublisherRequest.fromPartial(payload);
    return this.client.create(request);
  }

  delete(payload: DeepPartial<DeleteMQTTPublisherRequest>): Promise<DeleteTokenRequest_Response> {
    const request = DeleteMQTTPublisherRequest.fromPartial(payload);
    return this.client.delete(request);
  }

  update(payload: DeepPartial<UpdateMQTTPublisherRequest>): Promise<MQTTPublisher> {
    const request = UpdateMQTTPublisherRequest.fromPartial(payload);
    return this.client.update(request);
  }

  enable(payload: DeepPartial<EnableMQTTPublisherRequest>): Promise<EnableMQTTPublisherRequest_Response> {
    const request = EnableMQTTPublisherRequest.fromPartial(payload);
    return this.client.enable(request);
  }

  disable(payload: DeepPartial<DisableMQTTPublisherRequest>): Promise<DisableMQTTPublisherRequest_Response> {
    const request = DisableMQTTPublisherRequest.fromPartial(payload);
    return this.client.disable(request);
  }
}
