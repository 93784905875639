import { Client } from 'nice-grpc-web';
import { DeepPartial } from '../customer_compiled/field_service';
import { GrpcClientFactoryType } from './types';
import {
  CreateWebhookRequest,
  DeleteWebhookRequest,
  DeleteWebhookRequest_Response,
  DisableWebhookRequest,
  EnableWebhookRequest,
  ListWebhooksRequest,
  ListWebhooksRequest_Response,
  UpdateWebhookRequest,
  Webhook,
  WebhookHistoryRequest,
  WebhookHistoryRequest_Response,
  WebhookServiceDefinition
} from '../customer_compiled/webhook';

export default class WebhookService {
  private static instance: WebhookService;
  private readonly client: Client<typeof WebhookServiceDefinition>;

  private constructor(grpcClientFactory: GrpcClientFactoryType) {
    this.client = grpcClientFactory<typeof WebhookServiceDefinition>(
      WebhookServiceDefinition,
    );
  }

  static getInstance(grpcClientFactory: GrpcClientFactoryType) {
    if (!WebhookService.instance) {
      WebhookService.instance = new WebhookService(grpcClientFactory);
    }

    return WebhookService.instance;
  }

  list(payload: DeepPartial<ListWebhooksRequest>): Promise<ListWebhooksRequest_Response> {
    const request = ListWebhooksRequest.fromPartial(payload);
    return this.client.list(request);
  }

  enable(payload: DeepPartial<EnableWebhookRequest>): Promise<Webhook> {
    const request = EnableWebhookRequest.fromPartial(payload);
    return this.client.enable(request);
  }

  disable(payload: DeepPartial<DisableWebhookRequest>): Promise<Webhook> {
    const request = DisableWebhookRequest.fromPartial(payload);
    return this.client.disable(request);
  }

  history(payload: DeepPartial<WebhookHistoryRequest>): Promise<WebhookHistoryRequest_Response> {
    const request = WebhookHistoryRequest.fromPartial(payload);
    return this.client.history(request);
  }

  create(payload: DeepPartial<CreateWebhookRequest>): Promise<Webhook> {
    const request = CreateWebhookRequest.fromPartial(payload);
    return this.client.create(request);
  }

  update(payload: DeepPartial<UpdateWebhookRequest>): Promise<Webhook> {
    const request = UpdateWebhookRequest.fromPartial(payload);
    return this.client.update(request);
  }

  delete(payload: DeepPartial<DeleteWebhookRequest>): Promise<DeleteWebhookRequest_Response> {
    const request = DeleteWebhookRequest.fromPartial(payload);
    return this.client.delete(request);
  }
}
