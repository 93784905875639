import { computed } from 'vue';
import { createAcl } from 'vue-simple-acl';
import { CurrentUser } from 'hiber-grpc/src/customer_compiled/currentuser';
import pinia from '@/store';
import router from '@/router';
import { rules } from '@/acl/rules';

const user = computed<CurrentUser | null>(() => pinia.state.value?.user?.currentUser);

const simpleAcl = createAcl({
  user,
  rules,
  router,
  onDeniedRoute: '/user',
});

export default simpleAcl;
