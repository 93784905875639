import { Directive } from 'vue';

export const VARIOUS_PLACEHOLDER = 'Various...';
const bulkEdit: Directive<
  HTMLInputElement | HTMLTextAreaElement,
  { values: Array<string | number> | string | number; resetDirtyState: () => void }
> = {
  beforeMount(el, bindings) {
    const isStringOrNumber = ['string', 'number'].includes(typeof bindings.value.values);
    const allEqual = (arr) => arr.every((v) => v === arr[0]);

    const isMultiple =
      Array.isArray(bindings.value.values) && bindings.value.values.length > 1 && !allEqual(bindings.value.values);
    const inputNodes: Array<HTMLInputElement | HTMLTextAreaElement> = [];
    if (el.tagName === 'input' || el.tagName === 'textarea') {
      inputNodes.push(el);
    } else {
      if (el.getElementsByTagName('input').length > 0) {
        inputNodes.push(...Array.from(el.getElementsByTagName('input')));
      } else if (el.getElementsByTagName('textarea').length > 0) {
        inputNodes.push(...Array.from(el.getElementsByTagName('textarea')));
      }
    }

    inputNodes.forEach((inputNode) => {
      if (isStringOrNumber) {
        inputNode.value = bindings.value.values as string;
      } else {
        if (isMultiple) {
          inputNode.placeholder = VARIOUS_PLACEHOLDER;
          inputNode.value = '';
          inputNode.setAttribute('data-multiple', 'true');
        } else if (bindings.value.values[0]) {
          inputNode.value = bindings.value.values[0];
        }
      }
      inputNode.dispatchEvent(new Event('input'));
    });

    if (typeof bindings.value.resetDirtyState === 'function') {
      bindings.value.resetDirtyState();
    }
  },
};
export default bulkEdit;
