// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.19.1
// source: user.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import {
  DateMessage,
  Filter_ChildOrganizations,
  Filter_OrganizationPermissions,
  Filter_Roles,
  Filter_SupportPermissions,
  Pagination,
  Pagination_Result,
  UpdateClearableString,
} from "./base";

export const protobufPackage = "hiber.user";

export enum UserSort {
  EMAIL_ASC = "EMAIL_ASC",
  EMAIL_DESC = "EMAIL_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function userSortFromJSON(object: any): UserSort {
  switch (object) {
    case 0:
    case "EMAIL_ASC":
      return UserSort.EMAIL_ASC;
    case 1:
    case "EMAIL_DESC":
      return UserSort.EMAIL_DESC;
    case 2:
    case "NAME_ASC":
      return UserSort.NAME_ASC;
    case 3:
    case "NAME_DESC":
      return UserSort.NAME_DESC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserSort.UNRECOGNIZED;
  }
}

export function userSortToNumber(object: UserSort): number {
  switch (object) {
    case UserSort.EMAIL_ASC:
      return 0;
    case UserSort.EMAIL_DESC:
      return 1;
    case UserSort.NAME_ASC:
      return 2;
    case UserSort.NAME_DESC:
      return 3;
    case UserSort.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface User {
  id: string;
  email: string;
  name: string;
  organization: string;
  /** Roles for the current organization. */
  roles: string[];
  /** Permissions for the current organization. */
  permissions:
    | Filter_OrganizationPermissions
    | undefined;
  /**
   * Permissions for customer support.
   * Used for features typically reserved for customer support, or that behave differently
   * when used by a customer support operator.
   */
  supportPermissions: Filter_SupportPermissions | undefined;
  lastActivity: DateMessage | undefined;
}

export interface UserSelection {
  userIds: string[];
  byEmail?: string | undefined;
  byName?: string | undefined;
  search?:
    | string
    | undefined;
  /** Select users by roles for the current organization. */
  roles?:
    | Filter_Roles
    | undefined;
  /** Select users by permissions for the current organization. */
  permissions?:
    | Filter_OrganizationPermissions
    | undefined;
  /** Select users by permissions for customer support. */
  supportPermissions?: Filter_SupportPermissions | undefined;
}

export interface UserValidation {
  emailValidationRegex: string;
  allowedSignInProviders: UserValidation_SignInProvider[];
  requireVerifiedEmailAddresses: boolean;
}

export enum UserValidation_SignInProvider {
  PASSWORD = "PASSWORD",
  GOOGLE = "GOOGLE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function userValidation_SignInProviderFromJSON(object: any): UserValidation_SignInProvider {
  switch (object) {
    case 0:
    case "PASSWORD":
      return UserValidation_SignInProvider.PASSWORD;
    case 1:
    case "GOOGLE":
      return UserValidation_SignInProvider.GOOGLE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserValidation_SignInProvider.UNRECOGNIZED;
  }
}

export function userValidation_SignInProviderToNumber(object: UserValidation_SignInProvider): number {
  switch (object) {
    case UserValidation_SignInProvider.PASSWORD:
      return 0;
    case UserValidation_SignInProvider.GOOGLE:
      return 1;
    case UserValidation_SignInProvider.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface ListUsersRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the users to list. Optional, when omitted or empty everything is included. */
  selection?: UserSelection | undefined;
  pagination?: Pagination | undefined;
  sort: UserSort;
  childOrganizations?: Filter_ChildOrganizations | undefined;
}

export interface ListUsersRequest_Response {
  users: User[];
  request: ListUsersRequest | undefined;
  pagination: Pagination_Result | undefined;
}

export interface ListAccessRequestsRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the users to list. Optional, when omitted or empty everything is included. */
  selection?: UserSelection | undefined;
  pagination?: Pagination | undefined;
  sort: UserSort;
}

export interface ListAccessRequestsRequest_Response {
  users: User[];
  request: ListAccessRequestsRequest | undefined;
  pagination: Pagination_Result | undefined;
}

export interface ApproveUserRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  userIds: string[];
  /** Roles the new user should get. */
  roles:
    | Filter_Roles
    | undefined;
  /**
   * By default, the server returns an error when you don't specify any roles.
   * Set this to true to allow it.
   */
  overrideAllowNoRoles?: boolean | undefined;
}

export interface ApproveUserRequest_Response {
}

/**
 * Suspend a user, leaving them in the organization but blocking frontend access.
 *
 * Does not disable tokens! The user maybe still use those to access the system.
 */
export interface SuspendUserRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  userIds: string[];
}

export interface SuspendUserRequest_Response {
}

/** Enable a suspended a user, re-enabling frontend access. */
export interface UnsuspendUserRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  userIds: string[];
}

export interface UnsuspendUserRequest_Response {
}

export interface RemoveUserRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  userIds: string[];
  /** Remove all tokens created by this user. If this is not set, only the tokens with user permissions are removed. */
  removeAllTokens?: boolean | undefined;
}

export interface RemoveUserRequest_Response {
}

/** List all invited users (email addresses). */
export interface ListInvitationsRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Search the invited email addresses. */
  search?:
    | string
    | undefined;
  /** Whether to include invitations that were already accepted. */
  includeAccepted?: boolean | undefined;
}

export interface ListInvitationsRequest_Response {
  emails: string[];
}

/** Accept an invitation to an organization. */
export interface InviteUserRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The email address of the user you want to invite. */
  email: string;
  /** Invite the user again, even if there is an open invite. This can be done a limited amount of times. */
  retry?:
    | boolean
    | undefined;
  /** Roles the new user should get. */
  roles:
    | Filter_Roles
    | undefined;
  /**
   * By default, the server returns an error when you don't specify any roles.
   * Set this to true to allow it.
   */
  overrideAllowNoRoles?: boolean | undefined;
}

export interface InviteUserRequest_Response {
}

export interface CreateUserRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  email: string;
  name: string;
  /** Optional. If no password is given, the account can only be accessed using a password reset. */
  password?:
    | string
    | undefined;
  /** Optional. If a password is given, this will ask the user to change their password after the first login. */
  passwordReset?:
    | boolean
    | undefined;
  /** Roles the new user should get. */
  roles:
    | Filter_Roles
    | undefined;
  /** Send an automated email prompting the user to verify their email address. */
  sendVerificationMail?:
    | boolean
    | undefined;
  /** Send an automated email prompting the user to set a password. Recommended when password is not set. */
  sendPasswordResetMail?:
    | boolean
    | undefined;
  /**
   * When the user cannot be created, (i.e. they already exist because they are in a different organization)
   * we can send an invite instead, effectively calling InviteUserRequest with the email and roles.
   * Set this to true to allow this behaviour.
   */
  allowInviteInstead?:
    | boolean
    | undefined;
  /** By default, the server returns an error when you don't specify any roles. Set this to true to allow it. */
  overrideAllowNoRoles?: boolean | undefined;
}

export interface CreateUsersRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Users to create. Allows for individual impersonation and mail settings. */
  users: CreateUserRequest[];
  /** Roles the new users should get. Can be replaced for specific settings in the CreateUserRequest. */
  roles:
    | Filter_Roles
    | undefined;
  /** Send an automated email prompting the users to verify their email addresses. If true, applies to all users. */
  sendVerificationMail?:
    | boolean
    | undefined;
  /** Send an automated email prompting the users to set their password. If true, applies to all users. */
  sendPasswordResetMail?: boolean | undefined;
}

export interface CreateUsersRequest_Response {
  users: User[];
}

export interface ResetUserPasswordRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  userId: string;
}

export interface ResetUserPasswordRequest_Response {
}

export interface UpdateUserRoles {
}

export interface UpdateUserRoles_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  userIds: string[];
  update?:
    | { $case: "modify"; modify: UpdateUserRoles_Request_ModifyRoles }
    | { $case: "replace"; replace: UpdateUserRoles_Request_ReplaceRoles }
    | undefined;
  /**
   * By default, the server returns an error when you don't specify any roles.
   * Set this to true to allow it.
   */
  overrideAllowNoRoles: boolean;
}

/** Grant and remove roles on the current roles the users have. */
export interface UpdateUserRoles_Request_ModifyRoles {
  /** Grant roles in addition to the current roles the users have. */
  add: string[];
  /** Remove roles from the current roles the users have. */
  remove: string[];
}

/** Completely replace the roles the users have. */
export interface UpdateUserRoles_Request_ReplaceRoles {
  roles: string[];
}

export interface UpdateUserRoles_Response {
}

export interface GetUserValidationRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
}

export interface UpdateUserValidationRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** @deprecated */
  deprecatedEmailValidationRegex:
    | UpdateClearableString
    | undefined;
  /** The email validation regex the all users should match. */
  emailValidationRegex?:
    | string
    | undefined;
  /** Set the allowed sign-in providers. */
  replaceAllowedSignInProviders: UserValidation_SignInProvider[];
  /** Remove the limitation on sign-in providers. */
  allowAllSignInProviders?:
    | boolean
    | undefined;
  /** Whether the users added to this organization should have verified their email addresses. */
  requireVerifiedEmailAddresses?: boolean | undefined;
}

export interface TestUserValidationRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  validate?:
    | { $case: "selection"; selection: UserSelection }
    | { $case: "emailAddress"; emailAddress: string }
    | undefined;
  /** Test the given validation instead of the validation configured for your organization. */
  customValidation: UserValidation | undefined;
}

export interface TestUserValidationRequest_Response {
}

export interface UserActivitySummaryRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The user to request activity for. */
  userId: string;
  /** The dates to request user activity for. */
  dates: DateMessage[];
  /** Include activity for child organizations. */
  childOrganizations: Filter_ChildOrganizations | undefined;
}

export interface UserActivitySummaryRequest_Response {
  summaries: UserActivitySummaryRequest_Response_UserActivitySummary[];
}

export interface UserActivitySummaryRequest_Response_UserActivitySummary {
  organization: string;
  userId: string;
  date: DateMessage | undefined;
  actions: string[];
  totalRequests: number;
}

function createBaseUser(): User {
  return {
    id: "",
    email: "",
    name: "",
    organization: "",
    roles: [],
    permissions: undefined,
    supportPermissions: undefined,
    lastActivity: undefined,
  };
}

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.organization !== "") {
      writer.uint32(58).string(message.organization);
    }
    for (const v of message.roles) {
      writer.uint32(42).string(v!);
    }
    if (message.permissions !== undefined) {
      Filter_OrganizationPermissions.encode(message.permissions, writer.uint32(34).fork()).ldelim();
    }
    if (message.supportPermissions !== undefined) {
      Filter_SupportPermissions.encode(message.supportPermissions, writer.uint32(50).fork()).ldelim();
    }
    if (message.lastActivity !== undefined) {
      DateMessage.encode(message.lastActivity, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.roles.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.permissions = Filter_OrganizationPermissions.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.supportPermissions = Filter_SupportPermissions.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.lastActivity = DateMessage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<User>): User {
    return User.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<User>): User {
    const message = createBaseUser();
    message.id = object.id ?? "";
    message.email = object.email ?? "";
    message.name = object.name ?? "";
    message.organization = object.organization ?? "";
    message.roles = object.roles?.map((e) => e) || [];
    message.permissions = (object.permissions !== undefined && object.permissions !== null)
      ? Filter_OrganizationPermissions.fromPartial(object.permissions)
      : undefined;
    message.supportPermissions = (object.supportPermissions !== undefined && object.supportPermissions !== null)
      ? Filter_SupportPermissions.fromPartial(object.supportPermissions)
      : undefined;
    message.lastActivity = (object.lastActivity !== undefined && object.lastActivity !== null)
      ? DateMessage.fromPartial(object.lastActivity)
      : undefined;
    return message;
  },
};

function createBaseUserSelection(): UserSelection {
  return {
    userIds: [],
    byEmail: undefined,
    byName: undefined,
    search: undefined,
    roles: undefined,
    permissions: undefined,
    supportPermissions: undefined,
  };
}

export const UserSelection = {
  encode(message: UserSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.userIds) {
      writer.uint32(26).string(v!);
    }
    if (message.byEmail !== undefined) {
      writer.uint32(10).string(message.byEmail);
    }
    if (message.byName !== undefined) {
      writer.uint32(18).string(message.byName);
    }
    if (message.search !== undefined) {
      writer.uint32(34).string(message.search);
    }
    if (message.roles !== undefined) {
      Filter_Roles.encode(message.roles, writer.uint32(42).fork()).ldelim();
    }
    if (message.permissions !== undefined) {
      Filter_OrganizationPermissions.encode(message.permissions, writer.uint32(50).fork()).ldelim();
    }
    if (message.supportPermissions !== undefined) {
      Filter_SupportPermissions.encode(message.supportPermissions, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userIds.push(reader.string());
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.byEmail = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.byName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.search = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.roles = Filter_Roles.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.permissions = Filter_OrganizationPermissions.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.supportPermissions = Filter_SupportPermissions.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UserSelection>): UserSelection {
    return UserSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UserSelection>): UserSelection {
    const message = createBaseUserSelection();
    message.userIds = object.userIds?.map((e) => e) || [];
    message.byEmail = object.byEmail ?? undefined;
    message.byName = object.byName ?? undefined;
    message.search = object.search ?? undefined;
    message.roles = (object.roles !== undefined && object.roles !== null)
      ? Filter_Roles.fromPartial(object.roles)
      : undefined;
    message.permissions = (object.permissions !== undefined && object.permissions !== null)
      ? Filter_OrganizationPermissions.fromPartial(object.permissions)
      : undefined;
    message.supportPermissions = (object.supportPermissions !== undefined && object.supportPermissions !== null)
      ? Filter_SupportPermissions.fromPartial(object.supportPermissions)
      : undefined;
    return message;
  },
};

function createBaseUserValidation(): UserValidation {
  return { emailValidationRegex: "", allowedSignInProviders: [], requireVerifiedEmailAddresses: false };
}

export const UserValidation = {
  encode(message: UserValidation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.emailValidationRegex !== "") {
      writer.uint32(10).string(message.emailValidationRegex);
    }
    writer.uint32(18).fork();
    for (const v of message.allowedSignInProviders) {
      writer.int32(userValidation_SignInProviderToNumber(v));
    }
    writer.ldelim();
    if (message.requireVerifiedEmailAddresses !== false) {
      writer.uint32(24).bool(message.requireVerifiedEmailAddresses);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserValidation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserValidation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.emailValidationRegex = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.allowedSignInProviders.push(userValidation_SignInProviderFromJSON(reader.int32()));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.allowedSignInProviders.push(userValidation_SignInProviderFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.requireVerifiedEmailAddresses = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UserValidation>): UserValidation {
    return UserValidation.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UserValidation>): UserValidation {
    const message = createBaseUserValidation();
    message.emailValidationRegex = object.emailValidationRegex ?? "";
    message.allowedSignInProviders = object.allowedSignInProviders?.map((e) => e) || [];
    message.requireVerifiedEmailAddresses = object.requireVerifiedEmailAddresses ?? false;
    return message;
  },
};

function createBaseListUsersRequest(): ListUsersRequest {
  return {
    organization: undefined,
    selection: undefined,
    pagination: undefined,
    sort: UserSort.EMAIL_ASC,
    childOrganizations: undefined,
  };
}

export const ListUsersRequest = {
  encode(message: ListUsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      UserSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    if (message.sort !== UserSort.EMAIL_ASC) {
      writer.uint32(32).int32(userSortToNumber(message.sort));
    }
    if (message.childOrganizations !== undefined) {
      Filter_ChildOrganizations.encode(message.childOrganizations, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUsersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = UserSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sort = userSortFromJSON(reader.int32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.childOrganizations = Filter_ChildOrganizations.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListUsersRequest>): ListUsersRequest {
    return ListUsersRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListUsersRequest>): ListUsersRequest {
    const message = createBaseListUsersRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? UserSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.sort = object.sort ?? UserSort.EMAIL_ASC;
    message.childOrganizations = (object.childOrganizations !== undefined && object.childOrganizations !== null)
      ? Filter_ChildOrganizations.fromPartial(object.childOrganizations)
      : undefined;
    return message;
  },
};

function createBaseListUsersRequest_Response(): ListUsersRequest_Response {
  return { users: [], request: undefined, pagination: undefined };
}

export const ListUsersRequest_Response = {
  encode(message: ListUsersRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      User.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListUsersRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUsersRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListUsersRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(User.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListUsersRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListUsersRequest_Response>): ListUsersRequest_Response {
    return ListUsersRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListUsersRequest_Response>): ListUsersRequest_Response {
    const message = createBaseListUsersRequest_Response();
    message.users = object.users?.map((e) => User.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListUsersRequest.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseListAccessRequestsRequest(): ListAccessRequestsRequest {
  return { organization: undefined, selection: undefined, pagination: undefined, sort: UserSort.EMAIL_ASC };
}

export const ListAccessRequestsRequest = {
  encode(message: ListAccessRequestsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      UserSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    if (message.sort !== UserSort.EMAIL_ASC) {
      writer.uint32(32).int32(userSortToNumber(message.sort));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAccessRequestsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAccessRequestsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = UserSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sort = userSortFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListAccessRequestsRequest>): ListAccessRequestsRequest {
    return ListAccessRequestsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListAccessRequestsRequest>): ListAccessRequestsRequest {
    const message = createBaseListAccessRequestsRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? UserSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.sort = object.sort ?? UserSort.EMAIL_ASC;
    return message;
  },
};

function createBaseListAccessRequestsRequest_Response(): ListAccessRequestsRequest_Response {
  return { users: [], request: undefined, pagination: undefined };
}

export const ListAccessRequestsRequest_Response = {
  encode(message: ListAccessRequestsRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      User.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListAccessRequestsRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAccessRequestsRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAccessRequestsRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(User.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListAccessRequestsRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListAccessRequestsRequest_Response>): ListAccessRequestsRequest_Response {
    return ListAccessRequestsRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListAccessRequestsRequest_Response>): ListAccessRequestsRequest_Response {
    const message = createBaseListAccessRequestsRequest_Response();
    message.users = object.users?.map((e) => User.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListAccessRequestsRequest.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseApproveUserRequest(): ApproveUserRequest {
  return { organization: undefined, userIds: [], roles: undefined, overrideAllowNoRoles: undefined };
}

export const ApproveUserRequest = {
  encode(message: ApproveUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    for (const v of message.userIds) {
      writer.uint32(18).string(v!);
    }
    if (message.roles !== undefined) {
      Filter_Roles.encode(message.roles, writer.uint32(42).fork()).ldelim();
    }
    if (message.overrideAllowNoRoles !== undefined) {
      writer.uint32(32).bool(message.overrideAllowNoRoles);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApproveUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApproveUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userIds.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.roles = Filter_Roles.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.overrideAllowNoRoles = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ApproveUserRequest>): ApproveUserRequest {
    return ApproveUserRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ApproveUserRequest>): ApproveUserRequest {
    const message = createBaseApproveUserRequest();
    message.organization = object.organization ?? undefined;
    message.userIds = object.userIds?.map((e) => e) || [];
    message.roles = (object.roles !== undefined && object.roles !== null)
      ? Filter_Roles.fromPartial(object.roles)
      : undefined;
    message.overrideAllowNoRoles = object.overrideAllowNoRoles ?? undefined;
    return message;
  },
};

function createBaseApproveUserRequest_Response(): ApproveUserRequest_Response {
  return {};
}

export const ApproveUserRequest_Response = {
  encode(_: ApproveUserRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApproveUserRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApproveUserRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ApproveUserRequest_Response>): ApproveUserRequest_Response {
    return ApproveUserRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ApproveUserRequest_Response>): ApproveUserRequest_Response {
    const message = createBaseApproveUserRequest_Response();
    return message;
  },
};

function createBaseSuspendUserRequest(): SuspendUserRequest {
  return { organization: undefined, userIds: [] };
}

export const SuspendUserRequest = {
  encode(message: SuspendUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    for (const v of message.userIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SuspendUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSuspendUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SuspendUserRequest>): SuspendUserRequest {
    return SuspendUserRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SuspendUserRequest>): SuspendUserRequest {
    const message = createBaseSuspendUserRequest();
    message.organization = object.organization ?? undefined;
    message.userIds = object.userIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseSuspendUserRequest_Response(): SuspendUserRequest_Response {
  return {};
}

export const SuspendUserRequest_Response = {
  encode(_: SuspendUserRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SuspendUserRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSuspendUserRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SuspendUserRequest_Response>): SuspendUserRequest_Response {
    return SuspendUserRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<SuspendUserRequest_Response>): SuspendUserRequest_Response {
    const message = createBaseSuspendUserRequest_Response();
    return message;
  },
};

function createBaseUnsuspendUserRequest(): UnsuspendUserRequest {
  return { organization: undefined, userIds: [] };
}

export const UnsuspendUserRequest = {
  encode(message: UnsuspendUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    for (const v of message.userIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnsuspendUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnsuspendUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnsuspendUserRequest>): UnsuspendUserRequest {
    return UnsuspendUserRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UnsuspendUserRequest>): UnsuspendUserRequest {
    const message = createBaseUnsuspendUserRequest();
    message.organization = object.organization ?? undefined;
    message.userIds = object.userIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseUnsuspendUserRequest_Response(): UnsuspendUserRequest_Response {
  return {};
}

export const UnsuspendUserRequest_Response = {
  encode(_: UnsuspendUserRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnsuspendUserRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnsuspendUserRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UnsuspendUserRequest_Response>): UnsuspendUserRequest_Response {
    return UnsuspendUserRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UnsuspendUserRequest_Response>): UnsuspendUserRequest_Response {
    const message = createBaseUnsuspendUserRequest_Response();
    return message;
  },
};

function createBaseRemoveUserRequest(): RemoveUserRequest {
  return { organization: undefined, userIds: [], removeAllTokens: undefined };
}

export const RemoveUserRequest = {
  encode(message: RemoveUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    for (const v of message.userIds) {
      writer.uint32(18).string(v!);
    }
    if (message.removeAllTokens !== undefined) {
      writer.uint32(24).bool(message.removeAllTokens);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userIds.push(reader.string());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.removeAllTokens = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RemoveUserRequest>): RemoveUserRequest {
    return RemoveUserRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RemoveUserRequest>): RemoveUserRequest {
    const message = createBaseRemoveUserRequest();
    message.organization = object.organization ?? undefined;
    message.userIds = object.userIds?.map((e) => e) || [];
    message.removeAllTokens = object.removeAllTokens ?? undefined;
    return message;
  },
};

function createBaseRemoveUserRequest_Response(): RemoveUserRequest_Response {
  return {};
}

export const RemoveUserRequest_Response = {
  encode(_: RemoveUserRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveUserRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveUserRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RemoveUserRequest_Response>): RemoveUserRequest_Response {
    return RemoveUserRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<RemoveUserRequest_Response>): RemoveUserRequest_Response {
    const message = createBaseRemoveUserRequest_Response();
    return message;
  },
};

function createBaseListInvitationsRequest(): ListInvitationsRequest {
  return { organization: undefined, search: undefined, includeAccepted: undefined };
}

export const ListInvitationsRequest = {
  encode(message: ListInvitationsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.search !== undefined) {
      writer.uint32(18).string(message.search);
    }
    if (message.includeAccepted !== undefined) {
      writer.uint32(24).bool(message.includeAccepted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListInvitationsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListInvitationsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.search = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.includeAccepted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListInvitationsRequest>): ListInvitationsRequest {
    return ListInvitationsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListInvitationsRequest>): ListInvitationsRequest {
    const message = createBaseListInvitationsRequest();
    message.organization = object.organization ?? undefined;
    message.search = object.search ?? undefined;
    message.includeAccepted = object.includeAccepted ?? undefined;
    return message;
  },
};

function createBaseListInvitationsRequest_Response(): ListInvitationsRequest_Response {
  return { emails: [] };
}

export const ListInvitationsRequest_Response = {
  encode(message: ListInvitationsRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.emails) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListInvitationsRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListInvitationsRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.emails.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListInvitationsRequest_Response>): ListInvitationsRequest_Response {
    return ListInvitationsRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListInvitationsRequest_Response>): ListInvitationsRequest_Response {
    const message = createBaseListInvitationsRequest_Response();
    message.emails = object.emails?.map((e) => e) || [];
    return message;
  },
};

function createBaseInviteUserRequest(): InviteUserRequest {
  return { organization: undefined, email: "", retry: undefined, roles: undefined, overrideAllowNoRoles: undefined };
}

export const InviteUserRequest = {
  encode(message: InviteUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.retry !== undefined) {
      writer.uint32(24).bool(message.retry);
    }
    if (message.roles !== undefined) {
      Filter_Roles.encode(message.roles, writer.uint32(50).fork()).ldelim();
    }
    if (message.overrideAllowNoRoles !== undefined) {
      writer.uint32(40).bool(message.overrideAllowNoRoles);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.retry = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.roles = Filter_Roles.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.overrideAllowNoRoles = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<InviteUserRequest>): InviteUserRequest {
    return InviteUserRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<InviteUserRequest>): InviteUserRequest {
    const message = createBaseInviteUserRequest();
    message.organization = object.organization ?? undefined;
    message.email = object.email ?? "";
    message.retry = object.retry ?? undefined;
    message.roles = (object.roles !== undefined && object.roles !== null)
      ? Filter_Roles.fromPartial(object.roles)
      : undefined;
    message.overrideAllowNoRoles = object.overrideAllowNoRoles ?? undefined;
    return message;
  },
};

function createBaseInviteUserRequest_Response(): InviteUserRequest_Response {
  return {};
}

export const InviteUserRequest_Response = {
  encode(_: InviteUserRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteUserRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteUserRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<InviteUserRequest_Response>): InviteUserRequest_Response {
    return InviteUserRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<InviteUserRequest_Response>): InviteUserRequest_Response {
    const message = createBaseInviteUserRequest_Response();
    return message;
  },
};

function createBaseCreateUserRequest(): CreateUserRequest {
  return {
    organization: undefined,
    email: "",
    name: "",
    password: undefined,
    passwordReset: undefined,
    roles: undefined,
    sendVerificationMail: undefined,
    sendPasswordResetMail: undefined,
    allowInviteInstead: undefined,
    overrideAllowNoRoles: undefined,
  };
}

export const CreateUserRequest = {
  encode(message: CreateUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.password !== undefined) {
      writer.uint32(34).string(message.password);
    }
    if (message.passwordReset !== undefined) {
      writer.uint32(88).bool(message.passwordReset);
    }
    if (message.roles !== undefined) {
      Filter_Roles.encode(message.roles, writer.uint32(82).fork()).ldelim();
    }
    if (message.sendVerificationMail !== undefined) {
      writer.uint32(48).bool(message.sendVerificationMail);
    }
    if (message.sendPasswordResetMail !== undefined) {
      writer.uint32(56).bool(message.sendPasswordResetMail);
    }
    if (message.allowInviteInstead !== undefined) {
      writer.uint32(64).bool(message.allowInviteInstead);
    }
    if (message.overrideAllowNoRoles !== undefined) {
      writer.uint32(72).bool(message.overrideAllowNoRoles);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.password = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.passwordReset = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.roles = Filter_Roles.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.sendVerificationMail = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.sendPasswordResetMail = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.allowInviteInstead = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.overrideAllowNoRoles = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateUserRequest>): CreateUserRequest {
    return CreateUserRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateUserRequest>): CreateUserRequest {
    const message = createBaseCreateUserRequest();
    message.organization = object.organization ?? undefined;
    message.email = object.email ?? "";
    message.name = object.name ?? "";
    message.password = object.password ?? undefined;
    message.passwordReset = object.passwordReset ?? undefined;
    message.roles = (object.roles !== undefined && object.roles !== null)
      ? Filter_Roles.fromPartial(object.roles)
      : undefined;
    message.sendVerificationMail = object.sendVerificationMail ?? undefined;
    message.sendPasswordResetMail = object.sendPasswordResetMail ?? undefined;
    message.allowInviteInstead = object.allowInviteInstead ?? undefined;
    message.overrideAllowNoRoles = object.overrideAllowNoRoles ?? undefined;
    return message;
  },
};

function createBaseCreateUsersRequest(): CreateUsersRequest {
  return {
    organization: undefined,
    users: [],
    roles: undefined,
    sendVerificationMail: undefined,
    sendPasswordResetMail: undefined,
  };
}

export const CreateUsersRequest = {
  encode(message: CreateUsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    for (const v of message.users) {
      CreateUserRequest.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.roles !== undefined) {
      Filter_Roles.encode(message.roles, writer.uint32(50).fork()).ldelim();
    }
    if (message.sendVerificationMail !== undefined) {
      writer.uint32(32).bool(message.sendVerificationMail);
    }
    if (message.sendPasswordResetMail !== undefined) {
      writer.uint32(40).bool(message.sendPasswordResetMail);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUsersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.users.push(CreateUserRequest.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.roles = Filter_Roles.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sendVerificationMail = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.sendPasswordResetMail = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateUsersRequest>): CreateUsersRequest {
    return CreateUsersRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateUsersRequest>): CreateUsersRequest {
    const message = createBaseCreateUsersRequest();
    message.organization = object.organization ?? undefined;
    message.users = object.users?.map((e) => CreateUserRequest.fromPartial(e)) || [];
    message.roles = (object.roles !== undefined && object.roles !== null)
      ? Filter_Roles.fromPartial(object.roles)
      : undefined;
    message.sendVerificationMail = object.sendVerificationMail ?? undefined;
    message.sendPasswordResetMail = object.sendPasswordResetMail ?? undefined;
    return message;
  },
};

function createBaseCreateUsersRequest_Response(): CreateUsersRequest_Response {
  return { users: [] };
}

export const CreateUsersRequest_Response = {
  encode(message: CreateUsersRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      User.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUsersRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateUsersRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(User.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateUsersRequest_Response>): CreateUsersRequest_Response {
    return CreateUsersRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateUsersRequest_Response>): CreateUsersRequest_Response {
    const message = createBaseCreateUsersRequest_Response();
    message.users = object.users?.map((e) => User.fromPartial(e)) || [];
    return message;
  },
};

function createBaseResetUserPasswordRequest(): ResetUserPasswordRequest {
  return { organization: undefined, userId: "" };
}

export const ResetUserPasswordRequest = {
  encode(message: ResetUserPasswordRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetUserPasswordRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetUserPasswordRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ResetUserPasswordRequest>): ResetUserPasswordRequest {
    return ResetUserPasswordRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ResetUserPasswordRequest>): ResetUserPasswordRequest {
    const message = createBaseResetUserPasswordRequest();
    message.organization = object.organization ?? undefined;
    message.userId = object.userId ?? "";
    return message;
  },
};

function createBaseResetUserPasswordRequest_Response(): ResetUserPasswordRequest_Response {
  return {};
}

export const ResetUserPasswordRequest_Response = {
  encode(_: ResetUserPasswordRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetUserPasswordRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetUserPasswordRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ResetUserPasswordRequest_Response>): ResetUserPasswordRequest_Response {
    return ResetUserPasswordRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ResetUserPasswordRequest_Response>): ResetUserPasswordRequest_Response {
    const message = createBaseResetUserPasswordRequest_Response();
    return message;
  },
};

function createBaseUpdateUserRoles(): UpdateUserRoles {
  return {};
}

export const UpdateUserRoles = {
  encode(_: UpdateUserRoles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserRoles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserRoles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateUserRoles>): UpdateUserRoles {
    return UpdateUserRoles.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UpdateUserRoles>): UpdateUserRoles {
    const message = createBaseUpdateUserRoles();
    return message;
  },
};

function createBaseUpdateUserRoles_Request(): UpdateUserRoles_Request {
  return { organization: undefined, userIds: [], update: undefined, overrideAllowNoRoles: false };
}

export const UpdateUserRoles_Request = {
  encode(message: UpdateUserRoles_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    for (const v of message.userIds) {
      writer.uint32(18).string(v!);
    }
    switch (message.update?.$case) {
      case "modify":
        UpdateUserRoles_Request_ModifyRoles.encode(message.update.modify, writer.uint32(26).fork()).ldelim();
        break;
      case "replace":
        UpdateUserRoles_Request_ReplaceRoles.encode(message.update.replace, writer.uint32(34).fork()).ldelim();
        break;
    }
    if (message.overrideAllowNoRoles !== false) {
      writer.uint32(40).bool(message.overrideAllowNoRoles);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserRoles_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserRoles_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userIds.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.update = {
            $case: "modify",
            modify: UpdateUserRoles_Request_ModifyRoles.decode(reader, reader.uint32()),
          };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.update = {
            $case: "replace",
            replace: UpdateUserRoles_Request_ReplaceRoles.decode(reader, reader.uint32()),
          };
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.overrideAllowNoRoles = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateUserRoles_Request>): UpdateUserRoles_Request {
    return UpdateUserRoles_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateUserRoles_Request>): UpdateUserRoles_Request {
    const message = createBaseUpdateUserRoles_Request();
    message.organization = object.organization ?? undefined;
    message.userIds = object.userIds?.map((e) => e) || [];
    if (object.update?.$case === "modify" && object.update?.modify !== undefined && object.update?.modify !== null) {
      message.update = {
        $case: "modify",
        modify: UpdateUserRoles_Request_ModifyRoles.fromPartial(object.update.modify),
      };
    }
    if (object.update?.$case === "replace" && object.update?.replace !== undefined && object.update?.replace !== null) {
      message.update = {
        $case: "replace",
        replace: UpdateUserRoles_Request_ReplaceRoles.fromPartial(object.update.replace),
      };
    }
    message.overrideAllowNoRoles = object.overrideAllowNoRoles ?? false;
    return message;
  },
};

function createBaseUpdateUserRoles_Request_ModifyRoles(): UpdateUserRoles_Request_ModifyRoles {
  return { add: [], remove: [] };
}

export const UpdateUserRoles_Request_ModifyRoles = {
  encode(message: UpdateUserRoles_Request_ModifyRoles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.add) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.remove) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserRoles_Request_ModifyRoles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserRoles_Request_ModifyRoles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.add.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.remove.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateUserRoles_Request_ModifyRoles>): UpdateUserRoles_Request_ModifyRoles {
    return UpdateUserRoles_Request_ModifyRoles.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateUserRoles_Request_ModifyRoles>): UpdateUserRoles_Request_ModifyRoles {
    const message = createBaseUpdateUserRoles_Request_ModifyRoles();
    message.add = object.add?.map((e) => e) || [];
    message.remove = object.remove?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateUserRoles_Request_ReplaceRoles(): UpdateUserRoles_Request_ReplaceRoles {
  return { roles: [] };
}

export const UpdateUserRoles_Request_ReplaceRoles = {
  encode(message: UpdateUserRoles_Request_ReplaceRoles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.roles) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserRoles_Request_ReplaceRoles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserRoles_Request_ReplaceRoles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roles.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateUserRoles_Request_ReplaceRoles>): UpdateUserRoles_Request_ReplaceRoles {
    return UpdateUserRoles_Request_ReplaceRoles.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateUserRoles_Request_ReplaceRoles>): UpdateUserRoles_Request_ReplaceRoles {
    const message = createBaseUpdateUserRoles_Request_ReplaceRoles();
    message.roles = object.roles?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateUserRoles_Response(): UpdateUserRoles_Response {
  return {};
}

export const UpdateUserRoles_Response = {
  encode(_: UpdateUserRoles_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserRoles_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserRoles_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateUserRoles_Response>): UpdateUserRoles_Response {
    return UpdateUserRoles_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UpdateUserRoles_Response>): UpdateUserRoles_Response {
    const message = createBaseUpdateUserRoles_Response();
    return message;
  },
};

function createBaseGetUserValidationRequest(): GetUserValidationRequest {
  return { organization: undefined };
}

export const GetUserValidationRequest = {
  encode(message: GetUserValidationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserValidationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserValidationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<GetUserValidationRequest>): GetUserValidationRequest {
    return GetUserValidationRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetUserValidationRequest>): GetUserValidationRequest {
    const message = createBaseGetUserValidationRequest();
    message.organization = object.organization ?? undefined;
    return message;
  },
};

function createBaseUpdateUserValidationRequest(): UpdateUserValidationRequest {
  return {
    organization: undefined,
    deprecatedEmailValidationRegex: undefined,
    emailValidationRegex: undefined,
    replaceAllowedSignInProviders: [],
    allowAllSignInProviders: undefined,
    requireVerifiedEmailAddresses: undefined,
  };
}

export const UpdateUserValidationRequest = {
  encode(message: UpdateUserValidationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.deprecatedEmailValidationRegex !== undefined) {
      UpdateClearableString.encode(message.deprecatedEmailValidationRegex, writer.uint32(18).fork()).ldelim();
    }
    if (message.emailValidationRegex !== undefined) {
      writer.uint32(26).string(message.emailValidationRegex);
    }
    writer.uint32(34).fork();
    for (const v of message.replaceAllowedSignInProviders) {
      writer.int32(userValidation_SignInProviderToNumber(v));
    }
    writer.ldelim();
    if (message.allowAllSignInProviders !== undefined) {
      writer.uint32(40).bool(message.allowAllSignInProviders);
    }
    if (message.requireVerifiedEmailAddresses !== undefined) {
      writer.uint32(48).bool(message.requireVerifiedEmailAddresses);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserValidationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserValidationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deprecatedEmailValidationRegex = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.emailValidationRegex = reader.string();
          continue;
        case 4:
          if (tag === 32) {
            message.replaceAllowedSignInProviders.push(userValidation_SignInProviderFromJSON(reader.int32()));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.replaceAllowedSignInProviders.push(userValidation_SignInProviderFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.allowAllSignInProviders = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.requireVerifiedEmailAddresses = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateUserValidationRequest>): UpdateUserValidationRequest {
    return UpdateUserValidationRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateUserValidationRequest>): UpdateUserValidationRequest {
    const message = createBaseUpdateUserValidationRequest();
    message.organization = object.organization ?? undefined;
    message.deprecatedEmailValidationRegex =
      (object.deprecatedEmailValidationRegex !== undefined && object.deprecatedEmailValidationRegex !== null)
        ? UpdateClearableString.fromPartial(object.deprecatedEmailValidationRegex)
        : undefined;
    message.emailValidationRegex = object.emailValidationRegex ?? undefined;
    message.replaceAllowedSignInProviders = object.replaceAllowedSignInProviders?.map((e) => e) || [];
    message.allowAllSignInProviders = object.allowAllSignInProviders ?? undefined;
    message.requireVerifiedEmailAddresses = object.requireVerifiedEmailAddresses ?? undefined;
    return message;
  },
};

function createBaseTestUserValidationRequest(): TestUserValidationRequest {
  return { organization: undefined, validate: undefined, customValidation: undefined };
}

export const TestUserValidationRequest = {
  encode(message: TestUserValidationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    switch (message.validate?.$case) {
      case "selection":
        UserSelection.encode(message.validate.selection, writer.uint32(18).fork()).ldelim();
        break;
      case "emailAddress":
        writer.uint32(26).string(message.validate.emailAddress);
        break;
    }
    if (message.customValidation !== undefined) {
      UserValidation.encode(message.customValidation, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TestUserValidationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestUserValidationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.validate = { $case: "selection", selection: UserSelection.decode(reader, reader.uint32()) };
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.validate = { $case: "emailAddress", emailAddress: reader.string() };
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.customValidation = UserValidation.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TestUserValidationRequest>): TestUserValidationRequest {
    return TestUserValidationRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TestUserValidationRequest>): TestUserValidationRequest {
    const message = createBaseTestUserValidationRequest();
    message.organization = object.organization ?? undefined;
    if (
      object.validate?.$case === "selection" &&
      object.validate?.selection !== undefined &&
      object.validate?.selection !== null
    ) {
      message.validate = { $case: "selection", selection: UserSelection.fromPartial(object.validate.selection) };
    }
    if (
      object.validate?.$case === "emailAddress" &&
      object.validate?.emailAddress !== undefined &&
      object.validate?.emailAddress !== null
    ) {
      message.validate = { $case: "emailAddress", emailAddress: object.validate.emailAddress };
    }
    message.customValidation = (object.customValidation !== undefined && object.customValidation !== null)
      ? UserValidation.fromPartial(object.customValidation)
      : undefined;
    return message;
  },
};

function createBaseTestUserValidationRequest_Response(): TestUserValidationRequest_Response {
  return {};
}

export const TestUserValidationRequest_Response = {
  encode(_: TestUserValidationRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TestUserValidationRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestUserValidationRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TestUserValidationRequest_Response>): TestUserValidationRequest_Response {
    return TestUserValidationRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<TestUserValidationRequest_Response>): TestUserValidationRequest_Response {
    const message = createBaseTestUserValidationRequest_Response();
    return message;
  },
};

function createBaseUserActivitySummaryRequest(): UserActivitySummaryRequest {
  return { organization: undefined, userId: "", dates: [], childOrganizations: undefined };
}

export const UserActivitySummaryRequest = {
  encode(message: UserActivitySummaryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    for (const v of message.dates) {
      DateMessage.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.childOrganizations !== undefined) {
      Filter_ChildOrganizations.encode(message.childOrganizations, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserActivitySummaryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserActivitySummaryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.dates.push(DateMessage.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.childOrganizations = Filter_ChildOrganizations.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UserActivitySummaryRequest>): UserActivitySummaryRequest {
    return UserActivitySummaryRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UserActivitySummaryRequest>): UserActivitySummaryRequest {
    const message = createBaseUserActivitySummaryRequest();
    message.organization = object.organization ?? undefined;
    message.userId = object.userId ?? "";
    message.dates = object.dates?.map((e) => DateMessage.fromPartial(e)) || [];
    message.childOrganizations = (object.childOrganizations !== undefined && object.childOrganizations !== null)
      ? Filter_ChildOrganizations.fromPartial(object.childOrganizations)
      : undefined;
    return message;
  },
};

function createBaseUserActivitySummaryRequest_Response(): UserActivitySummaryRequest_Response {
  return { summaries: [] };
}

export const UserActivitySummaryRequest_Response = {
  encode(message: UserActivitySummaryRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.summaries) {
      UserActivitySummaryRequest_Response_UserActivitySummary.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserActivitySummaryRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserActivitySummaryRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.summaries.push(
            UserActivitySummaryRequest_Response_UserActivitySummary.decode(reader, reader.uint32()),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UserActivitySummaryRequest_Response>): UserActivitySummaryRequest_Response {
    return UserActivitySummaryRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UserActivitySummaryRequest_Response>): UserActivitySummaryRequest_Response {
    const message = createBaseUserActivitySummaryRequest_Response();
    message.summaries =
      object.summaries?.map((e) => UserActivitySummaryRequest_Response_UserActivitySummary.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserActivitySummaryRequest_Response_UserActivitySummary(): UserActivitySummaryRequest_Response_UserActivitySummary {
  return { organization: "", userId: "", date: undefined, actions: [], totalRequests: 0 };
}

export const UserActivitySummaryRequest_Response_UserActivitySummary = {
  encode(
    message: UserActivitySummaryRequest_Response_UserActivitySummary,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.date !== undefined) {
      DateMessage.encode(message.date, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.actions) {
      writer.uint32(34).string(v!);
    }
    if (message.totalRequests !== 0) {
      writer.uint32(40).uint32(message.totalRequests);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserActivitySummaryRequest_Response_UserActivitySummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserActivitySummaryRequest_Response_UserActivitySummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.date = DateMessage.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.actions.push(reader.string());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.totalRequests = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<UserActivitySummaryRequest_Response_UserActivitySummary>,
  ): UserActivitySummaryRequest_Response_UserActivitySummary {
    return UserActivitySummaryRequest_Response_UserActivitySummary.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UserActivitySummaryRequest_Response_UserActivitySummary>,
  ): UserActivitySummaryRequest_Response_UserActivitySummary {
    const message = createBaseUserActivitySummaryRequest_Response_UserActivitySummary();
    message.organization = object.organization ?? "";
    message.userId = object.userId ?? "";
    message.date = (object.date !== undefined && object.date !== null)
      ? DateMessage.fromPartial(object.date)
      : undefined;
    message.actions = object.actions?.map((e) => e) || [];
    message.totalRequests = object.totalRequests ?? 0;
    return message;
  },
};

export type UserServiceDefinition = typeof UserServiceDefinition;
export const UserServiceDefinition = {
  name: "UserService",
  fullName: "hiber.user.UserService",
  methods: {
    list: {
      name: "List",
      requestType: ListUsersRequest,
      requestStream: false,
      responseType: ListUsersRequest_Response,
      responseStream: false,
      options: {},
    },
    listAccessRequests: {
      name: "ListAccessRequests",
      requestType: ListAccessRequestsRequest,
      requestStream: false,
      responseType: ListAccessRequestsRequest_Response,
      responseStream: false,
      options: {},
    },
    approve: {
      name: "Approve",
      requestType: ApproveUserRequest,
      requestStream: false,
      responseType: ApproveUserRequest_Response,
      responseStream: false,
      options: {},
    },
    remove: {
      name: "Remove",
      requestType: RemoveUserRequest,
      requestStream: false,
      responseType: RemoveUserRequest_Response,
      responseStream: false,
      options: {},
    },
    invite: {
      name: "Invite",
      requestType: InviteUserRequest,
      requestStream: false,
      responseType: InviteUserRequest_Response,
      responseStream: false,
      options: {},
    },
    listInvitations: {
      name: "ListInvitations",
      requestType: ListInvitationsRequest,
      requestStream: false,
      responseType: ListInvitationsRequest_Response,
      responseStream: false,
      options: {},
    },
    createUser: {
      name: "CreateUser",
      requestType: CreateUserRequest,
      requestStream: false,
      responseType: User,
      responseStream: false,
      options: {},
    },
    createUsers: {
      name: "CreateUsers",
      requestType: CreateUsersRequest,
      requestStream: false,
      responseType: CreateUsersRequest_Response,
      responseStream: false,
      options: {},
    },
    resetPassword: {
      name: "ResetPassword",
      requestType: ResetUserPasswordRequest,
      requestStream: false,
      responseType: ResetUserPasswordRequest_Response,
      responseStream: false,
      options: {},
    },
    updateUserRoles: {
      name: "UpdateUserRoles",
      requestType: UpdateUserRoles_Request,
      requestStream: false,
      responseType: UpdateUserRoles_Response,
      responseStream: false,
      options: {},
    },
    getUserValidation: {
      name: "GetUserValidation",
      requestType: GetUserValidationRequest,
      requestStream: false,
      responseType: UserValidation,
      responseStream: false,
      options: {},
    },
    updateUserValidation: {
      name: "UpdateUserValidation",
      requestType: UpdateUserValidationRequest,
      requestStream: false,
      responseType: UserValidation,
      responseStream: false,
      options: {},
    },
    testUserValidation: {
      name: "TestUserValidation",
      requestType: TestUserValidationRequest,
      requestStream: false,
      responseType: TestUserValidationRequest_Response,
      responseStream: false,
      options: {},
    },
    suspend: {
      name: "Suspend",
      requestType: SuspendUserRequest,
      requestStream: false,
      responseType: SuspendUserRequest_Response,
      responseStream: false,
      options: {},
    },
    unsuspend: {
      name: "Unsuspend",
      requestType: UnsuspendUserRequest,
      requestStream: false,
      responseType: UnsuspendUserRequest_Response,
      responseStream: false,
      options: {},
    },
    activity: {
      name: "Activity",
      requestType: UserActivitySummaryRequest,
      requestStream: false,
      responseType: UserActivitySummaryRequest_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
