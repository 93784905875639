import { Client, CompatServiceDefinition } from 'nice-grpc-web';
import {
  DeepPartial,
  ListModemMessageBodyParsersRequest,
  ListModemMessageBodyParsersRequest_Response,
  ModemMessageBodyParser,
  ModemMessageBodyParserServiceDefinition,
  UpdateUploadedModemMessageBodyParserRequest,
  UploadModemMessageBodyParserRequest,
} from '../customer_compiled/modem_message_body_parser';

type GrpcClientFactoryType = <T extends CompatServiceDefinition>(service: T) => Client<T>;

export default class ModemMessageBodyParserService {
  private static instance: ModemMessageBodyParserService;
  private readonly client: Client<typeof ModemMessageBodyParserServiceDefinition>;

  private constructor(grpcClientFactory: GrpcClientFactoryType) {
    this.client = grpcClientFactory<typeof ModemMessageBodyParserServiceDefinition>(
      ModemMessageBodyParserServiceDefinition,
    );
  }

  static getInstance(grpcClientFactory: GrpcClientFactoryType) {
    if (!ModemMessageBodyParserService.instance) {
      ModemMessageBodyParserService.instance = new ModemMessageBodyParserService(grpcClientFactory);
    }

    return ModemMessageBodyParserService.instance;
  }

  list(payload: DeepPartial<ListModemMessageBodyParsersRequest>): Promise<ListModemMessageBodyParsersRequest_Response> {
    const request = ListModemMessageBodyParsersRequest.fromPartial(payload);
    return this.client.list(request);
  }

  fetchModemMessageBodyParserList(payload: DeepPartial<ListModemMessageBodyParsersRequest>): Promise<ListModemMessageBodyParsersRequest_Response> {
    const request = ListModemMessageBodyParsersRequest.fromPartial(payload);
    return this.client.list(request);
  }

  createModemMessageBodyParser(payload: DeepPartial<UploadModemMessageBodyParserRequest>): Promise<ModemMessageBodyParser> {
    const request = UploadModemMessageBodyParserRequest.fromPartial(payload);
    return this.client.upload(request);
  }

  updateModemMessageBodyParser(payload: DeepPartial<UpdateUploadedModemMessageBodyParserRequest>): Promise<ModemMessageBodyParser> {
    const request = UpdateUploadedModemMessageBodyParserRequest.fromPartial(payload);
    return this.client.updateUploaded(request);
  }
}
