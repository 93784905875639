import { FirebaseApp, FirebaseOptions, initializeApp } from '@firebase/app';

let app: FirebaseApp;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function initApp(config?: FirebaseOptions) {
  if (!config && !app) throw new Error('No firebase configuration object provided!');
  if (!app && config) {
    app = initializeApp(config);
  }
  return app;
}
