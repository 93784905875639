// CurrentUser
import {
  AcceptOrganizationInviteRequest,
  AcceptTermsAndConditionsRequest,
  AccessibleOrganizationsRequest,
  AccessibleOrganizationsRequest_Response,
  CurrentUser,
  CurrentUserRequest,
  CurrentUserServiceDefinition,
  DeepPartial,
  ListOrganizationInvitesRequest,
  RequestAccessRequest,
  UpdateMissionControlSettingsRequest,
  UpdateMissionControlSettingsRequest_Response,
} from 'hiber-grpc/src/customer_compiled/currentuser';
import { Client } from 'nice-grpc-web';
import { GrpcClientFactoryType } from './types';

export default class CurrentUserService {
  private static instance: CurrentUserService;
  private readonly client: Client<typeof CurrentUserServiceDefinition>;

  private constructor(grpcClientFactory: GrpcClientFactoryType) {
    this.client = grpcClientFactory<typeof CurrentUserServiceDefinition>(CurrentUserServiceDefinition);
  }

  static getInstance(grpcClientFactory: GrpcClientFactoryType) {
    if (!CurrentUserService.instance) {
      CurrentUserService.instance = new CurrentUserService(grpcClientFactory);
    }

    return CurrentUserService.instance;
  }

  getAccessibleOrganizations(search: string = '', page: number = 0, size: number = 10): Promise<AccessibleOrganizationsRequest_Response> {
    const request = AccessibleOrganizationsRequest.fromPartial({
      pagination: { size, page },
      search,
    });
    return this.client.accessibleOrganizations(request);
  }

  getCurrentUser(): Promise<CurrentUser> {
    const request = CurrentUserRequest.fromPartial({});
    return this.client.currentUser(request);
  }

  getOrganizationInvites() {
    const request = ListOrganizationInvitesRequest.fromPartial({});
    return this.client.listOrganizationInvites(request);
  }

  acceptOrganizationInvite(organization: string, setAsDefault: boolean) {
    const request = AcceptOrganizationInviteRequest.fromPartial({
      organization,
      defaultOrganization: setAsDefault,
    });
    return this.client.acceptOrganizationInvite(request);
  }

  requestUserAccessRequest(organizationId: string) {
    const request = RequestAccessRequest.fromPartial({ organization: organizationId,  });
    return this.client.requestAccess(request);
  }

  updateUserSettings(settings: any) {
    const request = UpdateMissionControlSettingsRequest.fromPartial({
      update: JSON.stringify(settings),
    });
    return this.client.updateMissionControlSettings(request);
  }

  updateSettings(payload: DeepPartial<UpdateMissionControlSettingsRequest>): Promise<UpdateMissionControlSettingsRequest_Response> {
    const request = UpdateMissionControlSettingsRequest.fromPartial(payload);
    return this.client.updateMissionControlSettings(request);
  }

  acceptTermsAndConditions(accepted: boolean) {
    const request = AcceptTermsAndConditionsRequest.fromPartial({ acceptTac: accepted });
    return this.client.acceptTermsAndConditions(request);
  }
}
