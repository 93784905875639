// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.19.1
// source: tag.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Area, Filter_Tags, Location, LocationSelection, Shape } from "./base";
import { File } from "./file";
import { HealthLevel } from "./health";

export const protobufPackage = "hiber.tag";

/**
 * Tag in your organization.
 * Tags can be assigned to devices and assets to group them together or mark a certain property.
 *
 * A Tag has three parts: its id in your organization, a Label that describes how it should be displayed, and
 * Metadata (which is only included when using the TagService) with additional information.
 */
export interface Tag {
  id: number;
  /** The label to display for this tag. */
  label:
    | Tag_Label
    | undefined;
  /**
   * Metadata for this tag.
   * This is typically not included in calls where the tag is repeated a lot, like the device list.
   * Use the TagService.List call to get the tags with Metadata.
   */
  metadata?: Tag_Metadata | undefined;
}

/** Label for a tag, containing all the information needed to display it. */
export interface Tag_Label {
  name: string;
  type: string;
}

/**
 * Metadata for a tag.
 * This is typically not included in calls where the tag is repeated a lot, like the device list.
 *
 * Use the TagService.List call to get the tags with Metadata.
 */
export interface Tag_Metadata {
  location?:
    | { $case: "locationPoint"; locationPoint: Location }
    | { $case: "locationArea"; locationArea: Area }
    | { $case: "locationShape"; locationShape: Shape }
    | undefined;
  /**
   * Files for this tag.
   * Typically an image of a place. See the File.media_type for more information.
   */
  files: File[];
}

export interface TagSelection {
  search: string[];
  names: string[];
  filter?: Filter_Tags | undefined;
  types: string[];
  location?: LocationSelection | undefined;
}

export interface UpdateTagsForItem {
  tagIdsToAdd: number[];
  tagIdsToRemove: number[];
  newTagsToAdd: Tag_Label[];
}

export interface ListTagsRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the tags to list. Optional, when omitted or empty everything is included. */
  selection?:
    | TagSelection
    | undefined;
  /** Count the assets for each tag in the response. */
  assetCount?:
    | boolean
    | undefined;
  /** Count the modems for each tag in the response. */
  modemCount?:
    | boolean
    | undefined;
  /** Count the webhooks for each tag in the response. */
  webhookCount?: boolean | undefined;
}

export interface ListTagsRequest_Response {
  tags: Tag[];
  request:
    | ListTagsRequest
    | undefined;
  /** map<tag-id, count> */
  tagAssetCount: { [key: number]: number };
  /** map<tag-id, count> */
  tagModemCount: { [key: number]: number };
  /** map<webhook-id, count> */
  tagWebhookCount: { [key: number]: number };
}

export interface ListTagsRequest_Response_TagAssetCountEntry {
  key: number;
  value: number;
}

export interface ListTagsRequest_Response_TagModemCountEntry {
  key: number;
  value: number;
}

export interface ListTagsRequest_Response_TagWebhookCountEntry {
  key: number;
  value: number;
}

export interface CreateTagRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  create: Tag_Label | undefined;
  location?:
    | { $case: "locationPoint"; locationPoint: Location }
    | { $case: "locationArea"; locationArea: Area }
    | { $case: "locationShape"; locationShape: Shape }
    | undefined;
  /**
   * Add files to the new tag. These can be just an identifier to an existing File, or a full file upload.
   * Keep in mind the grpc request size limitation.
   */
  files: File[];
}

export interface UpdateTagRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  id: number;
  update: Tag_Label | undefined;
  location?:
    | { $case: "locationPoint"; locationPoint: Location }
    | { $case: "locationArea"; locationArea: Area }
    | { $case: "locationShape"; locationShape: Shape }
    | { $case: "removeLocation"; removeLocation: boolean }
    | undefined;
  /**
   * Add files to the tag. These can be just an identifier to an existing File, or a full file upload.
   * Keep in mind the grpc request size limitation.
   */
  addFiles: File[];
  /** Remove files from the new tag and delete them from the system. */
  deleteFiles: string[];
}

export interface DeleteTagRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
  id: number;
}

export interface DeleteTagRequest_Response {
}

export interface TagHealth {
  tag: Tag | undefined;
  healthLevel: HealthLevel | undefined;
  mostSevere: boolean;
  assetCount: number;
  modemCount: number;
}

export interface TagHealth_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the tags to list. Optional, when omitted or empty everything is included. */
  selection?:
    | TagSelection
    | undefined;
  /** Count the assets for each tag in the response. */
  assetCount?:
    | boolean
    | undefined;
  /** Count the modems for each tag in the response. */
  modemCount?: boolean | undefined;
}

export interface TagHealth_Response {
  tagHealth: TagHealth[];
  request: TagHealth_Request | undefined;
}

function createBaseTag(): Tag {
  return { id: 0, label: undefined, metadata: undefined };
}

export const Tag = {
  encode(message: Tag, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.label !== undefined) {
      Tag_Label.encode(message.label, writer.uint32(26).fork()).ldelim();
    }
    if (message.metadata !== undefined) {
      Tag_Metadata.encode(message.metadata, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tag {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTag();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.label = Tag_Label.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.metadata = Tag_Metadata.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Tag>): Tag {
    return Tag.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Tag>): Tag {
    const message = createBaseTag();
    message.id = object.id ?? 0;
    message.label = (object.label !== undefined && object.label !== null)
      ? Tag_Label.fromPartial(object.label)
      : undefined;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Tag_Metadata.fromPartial(object.metadata)
      : undefined;
    return message;
  },
};

function createBaseTag_Label(): Tag_Label {
  return { name: "", type: "" };
}

export const Tag_Label = {
  encode(message: Tag_Label, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.type !== "") {
      writer.uint32(18).string(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tag_Label {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTag_Label();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Tag_Label>): Tag_Label {
    return Tag_Label.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Tag_Label>): Tag_Label {
    const message = createBaseTag_Label();
    message.name = object.name ?? "";
    message.type = object.type ?? "";
    return message;
  },
};

function createBaseTag_Metadata(): Tag_Metadata {
  return { location: undefined, files: [] };
}

export const Tag_Metadata = {
  encode(message: Tag_Metadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.location?.$case) {
      case "locationPoint":
        Location.encode(message.location.locationPoint, writer.uint32(34).fork()).ldelim();
        break;
      case "locationArea":
        Area.encode(message.location.locationArea, writer.uint32(42).fork()).ldelim();
        break;
      case "locationShape":
        Shape.encode(message.location.locationShape, writer.uint32(50).fork()).ldelim();
        break;
    }
    for (const v of message.files) {
      File.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tag_Metadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTag_Metadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 34) {
            break;
          }

          message.location = { $case: "locationPoint", locationPoint: Location.decode(reader, reader.uint32()) };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.location = { $case: "locationArea", locationArea: Area.decode(reader, reader.uint32()) };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.location = { $case: "locationShape", locationShape: Shape.decode(reader, reader.uint32()) };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.files.push(File.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Tag_Metadata>): Tag_Metadata {
    return Tag_Metadata.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Tag_Metadata>): Tag_Metadata {
    const message = createBaseTag_Metadata();
    if (
      object.location?.$case === "locationPoint" &&
      object.location?.locationPoint !== undefined &&
      object.location?.locationPoint !== null
    ) {
      message.location = { $case: "locationPoint", locationPoint: Location.fromPartial(object.location.locationPoint) };
    }
    if (
      object.location?.$case === "locationArea" &&
      object.location?.locationArea !== undefined &&
      object.location?.locationArea !== null
    ) {
      message.location = { $case: "locationArea", locationArea: Area.fromPartial(object.location.locationArea) };
    }
    if (
      object.location?.$case === "locationShape" &&
      object.location?.locationShape !== undefined &&
      object.location?.locationShape !== null
    ) {
      message.location = { $case: "locationShape", locationShape: Shape.fromPartial(object.location.locationShape) };
    }
    message.files = object.files?.map((e) => File.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTagSelection(): TagSelection {
  return { search: [], names: [], filter: undefined, types: [], location: undefined };
}

export const TagSelection = {
  encode(message: TagSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.search) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    if (message.filter !== undefined) {
      Filter_Tags.encode(message.filter, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.types) {
      writer.uint32(34).string(v!);
    }
    if (message.location !== undefined) {
      LocationSelection.encode(message.location, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TagSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTagSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.search.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.filter = Filter_Tags.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.types.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.location = LocationSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TagSelection>): TagSelection {
    return TagSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TagSelection>): TagSelection {
    const message = createBaseTagSelection();
    message.search = object.search?.map((e) => e) || [];
    message.names = object.names?.map((e) => e) || [];
    message.filter = (object.filter !== undefined && object.filter !== null)
      ? Filter_Tags.fromPartial(object.filter)
      : undefined;
    message.types = object.types?.map((e) => e) || [];
    message.location = (object.location !== undefined && object.location !== null)
      ? LocationSelection.fromPartial(object.location)
      : undefined;
    return message;
  },
};

function createBaseUpdateTagsForItem(): UpdateTagsForItem {
  return { tagIdsToAdd: [], tagIdsToRemove: [], newTagsToAdd: [] };
}

export const UpdateTagsForItem = {
  encode(message: UpdateTagsForItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.tagIdsToAdd) {
      writer.int64(v);
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.tagIdsToRemove) {
      writer.int64(v);
    }
    writer.ldelim();
    for (const v of message.newTagsToAdd) {
      Tag_Label.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTagsForItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTagsForItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.tagIdsToAdd.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.tagIdsToAdd.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 2:
          if (tag === 16) {
            message.tagIdsToRemove.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.tagIdsToRemove.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.newTagsToAdd.push(Tag_Label.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateTagsForItem>): UpdateTagsForItem {
    return UpdateTagsForItem.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateTagsForItem>): UpdateTagsForItem {
    const message = createBaseUpdateTagsForItem();
    message.tagIdsToAdd = object.tagIdsToAdd?.map((e) => e) || [];
    message.tagIdsToRemove = object.tagIdsToRemove?.map((e) => e) || [];
    message.newTagsToAdd = object.newTagsToAdd?.map((e) => Tag_Label.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListTagsRequest(): ListTagsRequest {
  return {
    organization: undefined,
    selection: undefined,
    assetCount: undefined,
    modemCount: undefined,
    webhookCount: undefined,
  };
}

export const ListTagsRequest = {
  encode(message: ListTagsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      TagSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.assetCount !== undefined) {
      writer.uint32(40).bool(message.assetCount);
    }
    if (message.modemCount !== undefined) {
      writer.uint32(24).bool(message.modemCount);
    }
    if (message.webhookCount !== undefined) {
      writer.uint32(32).bool(message.webhookCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListTagsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTagsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = TagSelection.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.assetCount = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.modemCount = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.webhookCount = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListTagsRequest>): ListTagsRequest {
    return ListTagsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListTagsRequest>): ListTagsRequest {
    const message = createBaseListTagsRequest();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? TagSelection.fromPartial(object.selection)
      : undefined;
    message.assetCount = object.assetCount ?? undefined;
    message.modemCount = object.modemCount ?? undefined;
    message.webhookCount = object.webhookCount ?? undefined;
    return message;
  },
};

function createBaseListTagsRequest_Response(): ListTagsRequest_Response {
  return { tags: [], request: undefined, tagAssetCount: {}, tagModemCount: {}, tagWebhookCount: {} };
}

export const ListTagsRequest_Response = {
  encode(message: ListTagsRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListTagsRequest.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    Object.entries(message.tagAssetCount).forEach(([key, value]) => {
      ListTagsRequest_Response_TagAssetCountEntry.encode({ key: key as any, value }, writer.uint32(42).fork()).ldelim();
    });
    Object.entries(message.tagModemCount).forEach(([key, value]) => {
      ListTagsRequest_Response_TagModemCountEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    Object.entries(message.tagWebhookCount).forEach(([key, value]) => {
      ListTagsRequest_Response_TagWebhookCountEntry.encode({ key: key as any, value }, writer.uint32(34).fork())
        .ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListTagsRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTagsRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListTagsRequest.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          const entry5 = ListTagsRequest_Response_TagAssetCountEntry.decode(reader, reader.uint32());
          if (entry5.value !== undefined) {
            message.tagAssetCount[entry5.key] = entry5.value;
          }
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = ListTagsRequest_Response_TagModemCountEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.tagModemCount[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = ListTagsRequest_Response_TagWebhookCountEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.tagWebhookCount[entry4.key] = entry4.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListTagsRequest_Response>): ListTagsRequest_Response {
    return ListTagsRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListTagsRequest_Response>): ListTagsRequest_Response {
    const message = createBaseListTagsRequest_Response();
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListTagsRequest.fromPartial(object.request)
      : undefined;
    message.tagAssetCount = Object.entries(object.tagAssetCount ?? {}).reduce<{ [key: number]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[globalThis.Number(key)] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    message.tagModemCount = Object.entries(object.tagModemCount ?? {}).reduce<{ [key: number]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[globalThis.Number(key)] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    message.tagWebhookCount = Object.entries(object.tagWebhookCount ?? {}).reduce<{ [key: number]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[globalThis.Number(key)] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseListTagsRequest_Response_TagAssetCountEntry(): ListTagsRequest_Response_TagAssetCountEntry {
  return { key: 0, value: 0 };
}

export const ListTagsRequest_Response_TagAssetCountEntry = {
  encode(message: ListTagsRequest_Response_TagAssetCountEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).int64(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int32(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListTagsRequest_Response_TagAssetCountEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTagsRequest_Response_TagAssetCountEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListTagsRequest_Response_TagAssetCountEntry>): ListTagsRequest_Response_TagAssetCountEntry {
    return ListTagsRequest_Response_TagAssetCountEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListTagsRequest_Response_TagAssetCountEntry>,
  ): ListTagsRequest_Response_TagAssetCountEntry {
    const message = createBaseListTagsRequest_Response_TagAssetCountEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseListTagsRequest_Response_TagModemCountEntry(): ListTagsRequest_Response_TagModemCountEntry {
  return { key: 0, value: 0 };
}

export const ListTagsRequest_Response_TagModemCountEntry = {
  encode(message: ListTagsRequest_Response_TagModemCountEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).int64(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int32(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListTagsRequest_Response_TagModemCountEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTagsRequest_Response_TagModemCountEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListTagsRequest_Response_TagModemCountEntry>): ListTagsRequest_Response_TagModemCountEntry {
    return ListTagsRequest_Response_TagModemCountEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListTagsRequest_Response_TagModemCountEntry>,
  ): ListTagsRequest_Response_TagModemCountEntry {
    const message = createBaseListTagsRequest_Response_TagModemCountEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseListTagsRequest_Response_TagWebhookCountEntry(): ListTagsRequest_Response_TagWebhookCountEntry {
  return { key: 0, value: 0 };
}

export const ListTagsRequest_Response_TagWebhookCountEntry = {
  encode(message: ListTagsRequest_Response_TagWebhookCountEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).int64(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int32(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListTagsRequest_Response_TagWebhookCountEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTagsRequest_Response_TagWebhookCountEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<ListTagsRequest_Response_TagWebhookCountEntry>,
  ): ListTagsRequest_Response_TagWebhookCountEntry {
    return ListTagsRequest_Response_TagWebhookCountEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListTagsRequest_Response_TagWebhookCountEntry>,
  ): ListTagsRequest_Response_TagWebhookCountEntry {
    const message = createBaseListTagsRequest_Response_TagWebhookCountEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseCreateTagRequest(): CreateTagRequest {
  return { organization: undefined, create: undefined, location: undefined, files: [] };
}

export const CreateTagRequest = {
  encode(message: CreateTagRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.create !== undefined) {
      Tag_Label.encode(message.create, writer.uint32(18).fork()).ldelim();
    }
    switch (message.location?.$case) {
      case "locationPoint":
        Location.encode(message.location.locationPoint, writer.uint32(34).fork()).ldelim();
        break;
      case "locationArea":
        Area.encode(message.location.locationArea, writer.uint32(42).fork()).ldelim();
        break;
      case "locationShape":
        Shape.encode(message.location.locationShape, writer.uint32(50).fork()).ldelim();
        break;
    }
    for (const v of message.files) {
      File.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateTagRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateTagRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.create = Tag_Label.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.location = { $case: "locationPoint", locationPoint: Location.decode(reader, reader.uint32()) };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.location = { $case: "locationArea", locationArea: Area.decode(reader, reader.uint32()) };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.location = { $case: "locationShape", locationShape: Shape.decode(reader, reader.uint32()) };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.files.push(File.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CreateTagRequest>): CreateTagRequest {
    return CreateTagRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateTagRequest>): CreateTagRequest {
    const message = createBaseCreateTagRequest();
    message.organization = object.organization ?? undefined;
    message.create = (object.create !== undefined && object.create !== null)
      ? Tag_Label.fromPartial(object.create)
      : undefined;
    if (
      object.location?.$case === "locationPoint" &&
      object.location?.locationPoint !== undefined &&
      object.location?.locationPoint !== null
    ) {
      message.location = { $case: "locationPoint", locationPoint: Location.fromPartial(object.location.locationPoint) };
    }
    if (
      object.location?.$case === "locationArea" &&
      object.location?.locationArea !== undefined &&
      object.location?.locationArea !== null
    ) {
      message.location = { $case: "locationArea", locationArea: Area.fromPartial(object.location.locationArea) };
    }
    if (
      object.location?.$case === "locationShape" &&
      object.location?.locationShape !== undefined &&
      object.location?.locationShape !== null
    ) {
      message.location = { $case: "locationShape", locationShape: Shape.fromPartial(object.location.locationShape) };
    }
    message.files = object.files?.map((e) => File.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateTagRequest(): UpdateTagRequest {
  return { organization: undefined, id: 0, update: undefined, location: undefined, addFiles: [], deleteFiles: [] };
}

export const UpdateTagRequest = {
  encode(message: UpdateTagRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.id !== 0) {
      writer.uint32(16).int64(message.id);
    }
    if (message.update !== undefined) {
      Tag_Label.encode(message.update, writer.uint32(26).fork()).ldelim();
    }
    switch (message.location?.$case) {
      case "locationPoint":
        Location.encode(message.location.locationPoint, writer.uint32(34).fork()).ldelim();
        break;
      case "locationArea":
        Area.encode(message.location.locationArea, writer.uint32(42).fork()).ldelim();
        break;
      case "locationShape":
        Shape.encode(message.location.locationShape, writer.uint32(50).fork()).ldelim();
        break;
      case "removeLocation":
        writer.uint32(56).bool(message.location.removeLocation);
        break;
    }
    for (const v of message.addFiles) {
      File.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.deleteFiles) {
      writer.uint32(74).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTagRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTagRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.update = Tag_Label.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.location = { $case: "locationPoint", locationPoint: Location.decode(reader, reader.uint32()) };
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.location = { $case: "locationArea", locationArea: Area.decode(reader, reader.uint32()) };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.location = { $case: "locationShape", locationShape: Shape.decode(reader, reader.uint32()) };
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.location = { $case: "removeLocation", removeLocation: reader.bool() };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.addFiles.push(File.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.deleteFiles.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateTagRequest>): UpdateTagRequest {
    return UpdateTagRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateTagRequest>): UpdateTagRequest {
    const message = createBaseUpdateTagRequest();
    message.organization = object.organization ?? undefined;
    message.id = object.id ?? 0;
    message.update = (object.update !== undefined && object.update !== null)
      ? Tag_Label.fromPartial(object.update)
      : undefined;
    if (
      object.location?.$case === "locationPoint" &&
      object.location?.locationPoint !== undefined &&
      object.location?.locationPoint !== null
    ) {
      message.location = { $case: "locationPoint", locationPoint: Location.fromPartial(object.location.locationPoint) };
    }
    if (
      object.location?.$case === "locationArea" &&
      object.location?.locationArea !== undefined &&
      object.location?.locationArea !== null
    ) {
      message.location = { $case: "locationArea", locationArea: Area.fromPartial(object.location.locationArea) };
    }
    if (
      object.location?.$case === "locationShape" &&
      object.location?.locationShape !== undefined &&
      object.location?.locationShape !== null
    ) {
      message.location = { $case: "locationShape", locationShape: Shape.fromPartial(object.location.locationShape) };
    }
    if (
      object.location?.$case === "removeLocation" &&
      object.location?.removeLocation !== undefined &&
      object.location?.removeLocation !== null
    ) {
      message.location = { $case: "removeLocation", removeLocation: object.location.removeLocation };
    }
    message.addFiles = object.addFiles?.map((e) => File.fromPartial(e)) || [];
    message.deleteFiles = object.deleteFiles?.map((e) => e) || [];
    return message;
  },
};

function createBaseDeleteTagRequest(): DeleteTagRequest {
  return { organization: undefined, id: 0 };
}

export const DeleteTagRequest = {
  encode(message: DeleteTagRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.id !== 0) {
      writer.uint32(16).int64(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTagRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteTagRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteTagRequest>): DeleteTagRequest {
    return DeleteTagRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteTagRequest>): DeleteTagRequest {
    const message = createBaseDeleteTagRequest();
    message.organization = object.organization ?? undefined;
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseDeleteTagRequest_Response(): DeleteTagRequest_Response {
  return {};
}

export const DeleteTagRequest_Response = {
  encode(_: DeleteTagRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTagRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteTagRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteTagRequest_Response>): DeleteTagRequest_Response {
    return DeleteTagRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<DeleteTagRequest_Response>): DeleteTagRequest_Response {
    const message = createBaseDeleteTagRequest_Response();
    return message;
  },
};

function createBaseTagHealth(): TagHealth {
  return { tag: undefined, healthLevel: undefined, mostSevere: false, assetCount: 0, modemCount: 0 };
}

export const TagHealth = {
  encode(message: TagHealth, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tag !== undefined) {
      Tag.encode(message.tag, writer.uint32(10).fork()).ldelim();
    }
    if (message.healthLevel !== undefined) {
      HealthLevel.encode(message.healthLevel, writer.uint32(18).fork()).ldelim();
    }
    if (message.mostSevere !== false) {
      writer.uint32(24).bool(message.mostSevere);
    }
    if (message.assetCount !== 0) {
      writer.uint32(32).uint32(message.assetCount);
    }
    if (message.modemCount !== 0) {
      writer.uint32(40).uint32(message.modemCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TagHealth {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTagHealth();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tag = Tag.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.healthLevel = HealthLevel.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.mostSevere = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assetCount = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.modemCount = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TagHealth>): TagHealth {
    return TagHealth.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TagHealth>): TagHealth {
    const message = createBaseTagHealth();
    message.tag = (object.tag !== undefined && object.tag !== null) ? Tag.fromPartial(object.tag) : undefined;
    message.healthLevel = (object.healthLevel !== undefined && object.healthLevel !== null)
      ? HealthLevel.fromPartial(object.healthLevel)
      : undefined;
    message.mostSevere = object.mostSevere ?? false;
    message.assetCount = object.assetCount ?? 0;
    message.modemCount = object.modemCount ?? 0;
    return message;
  },
};

function createBaseTagHealth_Request(): TagHealth_Request {
  return { organization: undefined, selection: undefined, assetCount: undefined, modemCount: undefined };
}

export const TagHealth_Request = {
  encode(message: TagHealth_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      TagSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.assetCount !== undefined) {
      writer.uint32(24).bool(message.assetCount);
    }
    if (message.modemCount !== undefined) {
      writer.uint32(32).bool(message.modemCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TagHealth_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTagHealth_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = TagSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.assetCount = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.modemCount = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TagHealth_Request>): TagHealth_Request {
    return TagHealth_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TagHealth_Request>): TagHealth_Request {
    const message = createBaseTagHealth_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? TagSelection.fromPartial(object.selection)
      : undefined;
    message.assetCount = object.assetCount ?? undefined;
    message.modemCount = object.modemCount ?? undefined;
    return message;
  },
};

function createBaseTagHealth_Response(): TagHealth_Response {
  return { tagHealth: [], request: undefined };
}

export const TagHealth_Response = {
  encode(message: TagHealth_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tagHealth) {
      TagHealth.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      TagHealth_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TagHealth_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTagHealth_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tagHealth.push(TagHealth.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = TagHealth_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TagHealth_Response>): TagHealth_Response {
    return TagHealth_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TagHealth_Response>): TagHealth_Response {
    const message = createBaseTagHealth_Response();
    message.tagHealth = object.tagHealth?.map((e) => TagHealth.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? TagHealth_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

/**
 * Tag management api calls. You can already get tags for objects when you get their data, and even create new tags
 * when updating them, so these calls are meant for easier tag management if you need it.
 */
export type TagServiceDefinition = typeof TagServiceDefinition;
export const TagServiceDefinition = {
  name: "TagService",
  fullName: "hiber.tag.TagService",
  methods: {
    list: {
      name: "List",
      requestType: ListTagsRequest,
      requestStream: false,
      responseType: ListTagsRequest_Response,
      responseStream: false,
      options: {},
    },
    create: {
      name: "Create",
      requestType: CreateTagRequest,
      requestStream: false,
      responseType: Tag,
      responseStream: false,
      options: {},
    },
    update: {
      name: "Update",
      requestType: UpdateTagRequest,
      requestStream: false,
      responseType: Tag,
      responseStream: false,
      options: {},
    },
    delete: {
      name: "Delete",
      requestType: DeleteTagRequest,
      requestStream: false,
      responseType: DeleteTagRequest_Response,
      responseStream: false,
      options: {},
    },
    /** Count the tags in your organization by health. */
    health: {
      name: "Health",
      requestType: TagHealth_Request,
      requestStream: false,
      responseType: TagHealth_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
