import { getAuth } from '@firebase/auth';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import useEmailVerification from '@/hooks/useEmailVerification';
import { useOrganizationStore } from '@/store/organization';
import { Organization_Feature } from 'hiber-grpc/src/customer_compiled/organization';
import TagService from 'hiber-grpc/src/services/tags';
import { getGrpcClient } from '@/services/grpc-client';
import { Rule } from '@/acl/rules';
import WebhookService from 'hiber-grpc/src/services/webhooks';
import TokensService from 'hiber-grpc/src/services/tokens';
import SlackIntegrationService from 'hiber-grpc/src/services/slack-integration';
import MqttService from 'hiber-grpc/src/services/mqtt';
import CertificatesService from 'hiber-grpc/src/services/certificates';
import AssetsService from 'hiber-grpc/src/services/assets';
import ModemMessageBodyParserService from 'hiber-grpc/src/services/modem-message-body-parser';
import FieldService from 'hiber-grpc/src/services/field';

async function webhookBeforeEnterGuard(to, _from, next) {
  if (!to.params.id || Number.isNaN(parseInt(to.params.id))) {
    next({ name: 'developer-tools', query: { organization: to.query.organization } });
  } else {
    const webhooks = await WebhookService.getInstance(getGrpcClient).list({
      selection: { webhooks: { include: [to.params.id] } },
    });
    if (webhooks.webhooks.length) {
      next();
    } else {
      next({ name: 'view-developer-tools', query: { organization: to.query.organization } });
    }
  }
}

export const routes = {
  dashboard: {
    name: 'dashboard',
    path: '/',
    component: () => import('../templates/AppLayout.vue'),
    meta: { requiresAuth: true },
    redirect: '/dashboard',
    beforeEnter: async (to, _from, next) => {
      const organizationStore = useOrganizationStore();
      try {
        // fetch organization details and check for permissions and feature flags
        await organizationStore.fetchOrganization(organizationStore.organizationImpersonation);

        const { meta } = to;
        const isAllowed = organizationStore.hasFeatures(meta.features as Organization_Feature[]);
        if (!isAllowed) {
          next({ path: routes.dashboard.path, query: { ...to.query } });
        } else {
          next();
        }
      } catch (error) {
        // reset impersonation and go to root path
        if (organizationStore.organizationImpersonation !== '') {
          organizationStore.organizationImpersonation = '';
          next({ path: routes.dashboard.path });
        }
        // otherwise the call to default organization fails so we will logout the user
        await getAuth().signOut();
        next({ path: '/login' });
      }
    },
  },
  devices: {
    name: 'devices',
    path: '/devices',
    alias: '/devices',
    component: () => import('../pages/devices/DevicesPage.vue'),
    meta: {
      can: Rule.VIEW_DEVICES,
    },
  },
  assets: {
    name: 'assets',
    path: '/assets',
    redirect: 'assets/list',
    component: () => import('../pages/assets/AssetsPage.vue'),
    meta: {
      can: Rule.VIEW_ASSETS,
      onDeniedRoute: '/dashboard',
    },
    children: [
      {
        path: 'list',
        name: 'assets-table',
        component: () => import('../pages/assets/AssetList.vue'),
        meta: {},
      },
      {
        path: ':id/update',
        name: 'asset-update',
        component: () => import('../pages/assets/AssetManagement.vue'),
        props: true,
        meta: {
          label: 'Edit Asset',
        },
      },
      {
        path: ':id/assign',
        name: 'asset-assignment',
        component: () => import('../pages/assets/AssetAssignments.vue'),
        props: true,
        meta: {
          label: 'Device Assignment',
        },
        beforeEnter: async (to, _from, next) => {
          if (!to.params.id) {
            next({ name: 'assets', query: { organization: to.query.organization } });
          } else {
            try {
              const assetsQuery = await AssetsService.getInstance(getGrpcClient).list({
                selection: { identifiers: [to.params.id] },
              });
              if (!assetsQuery.assets.length) {
                return next({ name: 'assets', query: { organization: to.query.organization } });
              }
              next();
            } catch (e) {
              next({ name: 'assets', query: { organization: to.query.organization } });
            }
          }
        },
      },
      {
        path: 'create',
        name: 'asset-create',
        component: () => import('../pages/assets/AssetManagement.vue'),
        props: true,
        meta: {
          label: 'Create Asset',
        },
      },
    ],
  },
  hilo: {
    name: 'hilo',
    path: '/dashboard',
    component: () => import('../pages/hilo/HiloPage.vue'),
    props: true,
    meta: {
      can: Rule.VIEW_DEVICES,
      label: 'HiberHilo',
    },
    children: [
      {
        path: '',
        name: 'overview-page',
        component: () => import('../pages/hilo/OverviewPage.vue'),
      },
      {
        path: 'well/:id?',
        name: 'well-page',
        redirect: (to) => ({
          name: 'sensor-page',
          params: { tab: 'all', id: to.params.id },
          query: { ...to.query },
        }),
        component: () => import('../pages/hilo/WellPage.vue'),
        props: true,
        children: [
          {
            path: 'tab/:tab',
            name: 'sensor-page',
            component: () => import('../pages/hilo/SensorPage.vue'),
            props: true,
            beforeEnter: async (to, _from, next) => {
              if (!to.params.id || Number.isNaN(parseInt(to.params.id))) {
                next({ name: 'overview-page', query: { organization: to.query.organization } });
              } else {
                const tagsQuery = await TagService.getInstance(getGrpcClient).fetchTags({
                  selection: { filter: { include: [to.params.id] } },
                });
                if (tagsQuery.tags.length < 1) {
                  next({ name: 'overview-page', query: { organization: to.query.organization } });
                } else {
                  next();
                }
              }
            },
          },
        ],
      },
    ],
  },
  settings: {
    name: 'settings',
    path: '/settings',
    component: () => import('../pages/settings/SettingsPage.vue'),
    children: [
      {
        path: '',
        redirect: '/settings/tags',
        name: 'view-settings',
        component: () => import('../pages/settings/ViewSettings.vue'),
        children: [
          {
            fullPath: '/settings/tags',
            path: 'tags',
            name: 'tags-settings',
            component: () => import('../pages/settings/TagsSettings.vue'),
            meta: {
              label: 'Tags',
            },
          },
          {
            fullPath: '/settings/alarms',
            path: 'alarms',
            name: 'alarms-settings',
            component: () => import('../pages/settings/AlarmSettings.vue'),
            meta: {
              label: 'Alarms',
              can: Rule.VIEW_ALARMS,
              onDeniedRoute: '/settings/tags',
            },
          },
          {
            fullPath: '/settings/accounts',
            path: 'accounts',
            name: 'accounts-settings',
            component: () => import('../pages/settings/AccountsManagement.vue'),
            meta: {
              label: 'Users & Accounts',
              can: Rule.VIEW_USERS,
              onDeniedRoute: '/settings/tags',
            },
          },
          {
            fullPath: '/settings/organizations',
            path: 'organizations',
            name: 'organization-settings',
            component: () => import('../pages/settings/Organizations.vue'),
            meta: {
              label: 'Sub-organizations',
            },
          },
          {
            fullPath: '/settings/organization-info',
            path: 'organization-info',
            name: 'organization-info',
            component: () => import('../pages/settings/OrganizationInfo.vue'),
            meta: {
              label: 'Organization info',
            },
          },
        ],
      },
      {
        path: 'tags/:id/update',
        name: 'tag-update',
        component: () => import('../pages/settings/TagManagement.vue'),
        props: true,
        meta: {
          label: 'Edit Tag',
        },
      },
      {
        path: 'tags/create',
        name: 'tag-create',
        component: () => import('../pages/settings/TagManagement.vue'),
        props: true,
        meta: {
          label: 'Create Tag',
        },
      },
      {
        path: 'alarms/:id/update',
        name: 'alarm-update',
        component: () => import('../pages/settings/AlarmManagement.vue'),
        props: true,
        meta: {
          label: 'Edit Alarm',
        },
      },
      {
        path: 'alarms/create',
        name: 'alarm-create',
        component: () => import('../pages/settings/AlarmManagement.vue'),
        props: true,
        meta: {
          label: 'Create Alarm',
        },
      },
    ],
  },
  transfers: {
    path: '/transfers',
    redirect: '/transfers/inbound',
    name: 'transfers',
    meta: {
      can: Rule.VIEW_TRANSFERS,
    },
    component: () => import('../pages/transfers/TransfersPage.vue'),
    children: [
      {
        path: 'inbound',
        name: 'inbound-transfers',
        component: () => import('../pages/transfers/InboundTransfersPage.vue'),
        meta: {
          label: 'Inbound transfers',
        },
      },
      {
        path: 'outbound',
        name: 'outbound-transfers',
        component: () => import('../pages/transfers/OutboundTransfersPage.vue'),
        meta: {
          label: 'Outbound transfers',
        },
      },
    ],
  },
  developer: {
    path: '/developer-tools',
    name: 'developer-tools',
    component: () => import('../pages/developer-tools/DeveloperTools.vue'),
    children: [
      {
        path: '',
        redirect: '/developer-tools/webhooks',
        name: 'view-developer-tools',
        component: () => import('../pages/developer-tools/TabsPage.vue'),
        children: [
          {
            fullPath: '/developer-tools/webhooks',
            path: 'webhooks',
            component: () => import('../pages/developer-tools/Webhooks.vue'),
            meta: {
              label: 'Webhooks',
              can: Rule.PUBLISHERS,
            },
          },
          {
            fullPath: '/developer-tools/tokens',
            path: 'tokens',
            component: () => import('../pages/developer-tools/Tokens.vue'),
            meta: {
              label: 'Tokens',
              can: Rule.MANAGE_TOKENS,
            },
          },
          {
            fullPath: '/developer-tools/parsers',
            path: 'parsers',
            component: () => import('../pages/developer-tools/Parsers.vue'),
            meta: {
              label: 'Parsers',
              can: Rule.MANAGE_PARSERS,
            },
          },
          {
            fullPath: '/developer-tools/fields',
            path: 'fields',
            component: () => import('../pages/developer-tools/Fields.vue'),
            meta: {
              label: 'Fields',
              can: Rule.MANAGE_PARSERS,
            },
          },
          {
            fullPath: '/developer-tools/slack',
            path: 'slack',
            component: () => import('../pages/developer-tools/Slack.vue'),
            meta: {
              label: 'Slack',
              can: Rule.PUBLISHERS,
            },
          },
          {
            fullPath: '/developer-tools/mqtt',
            path: 'mqtt',
            component: () => import('../pages/developer-tools/Mqtt.vue'),
            meta: {
              label: 'MQTT',
              can: Rule.PUBLISHERS,
            },
          },
          {
            fullPath: '/developer-tools/certificates',
            path: 'certificates',
            component: () => import('../pages/developer-tools/Certificates.vue'),
            meta: {
              label: 'Certificates',
            },
          },
        ],
      },
      {
        path: 'webhooks/:id/history',
        name: 'webhook-history',
        component: () => import('../pages/developer-tools/WebhookHistory.vue'),
        props: true,
        beforeEnter: webhookBeforeEnterGuard,
        meta: {
          label: 'Webhook history',
        },
      },
      {
        path: 'webhooks/create',
        name: 'webhook-create',
        component: () => import('../pages/developer-tools/WebhookManagement.vue'),
        meta: {
          label: 'Create webhook',
        },
      },
      {
        path: 'tokens/create',
        name: 'token-create',
        component: () => import('../pages/developer-tools/TokenManagement.vue'),
        meta: {
          label: 'Create token',
        },
      },
      {
        path: 'parsers/create',
        name: 'parser-create',
        component: () => import('../pages/developer-tools/ParserManagement.vue'),
        meta: {
          label: 'Create parser',
        },
      },
      {
        path: 'mqtt/create',
        name: 'mqtt-create',
        component: () => import('../pages/developer-tools/MqttManagement.vue'),
        meta: {
          label: 'Create MQTT Publisher',
        },
      },
      {
        path: 'certificates/upload',
        name: 'certificates-upload',
        component: () => import('../pages/developer-tools/CertificateManagement.vue'),
        meta: {
          label: 'Upload Certificate',
        },
      },
      {
        path: 'slack/create',
        name: 'slack-create',
        component: () => import('../pages/developer-tools/SlackPublisherManagement.vue'),
        meta: {
          label: 'Create slack integration',
        },
      },
      {
        path: 'webhooks/:id/update',
        name: 'webhook-edit',
        component: () => import('../pages/developer-tools/WebhookManagement.vue'),
        props: true,
        beforeEnter: webhookBeforeEnterGuard,
        meta: {
          label: 'Edit webhook',
        },
      },
      {
        path: 'parsers/:identifier/update',
        name: 'parser-edit',
        component: () => import('../pages/developer-tools/ParserManagement.vue'),
        props: true,
        beforeEnter: async (to, _from, next) => {
          if (!to.params.identifier) {
            next({ path: '/developer-tools/parsers', query: { organization: to.query.organization } });
          } else {
            const parsers = await ModemMessageBodyParserService.getInstance(getGrpcClient).list({
              selection: { search: to.params.identifier },
            });
            if (parsers.parsers.length) {
              next();
            } else {
              next({ path: '/developer-tools/parsers', query: { organization: to.query.organization } });
            }
          }
        },
        meta: {
          label: 'Edit parser',
        },
      },
      // {
      //   path: 'fields/:identifier/update',
      //   name: 'field-edit',
      //   component: () => import('../pages/developer-tools/FieldManagement.vue'),
      //   props: true,
      //   beforeEnter: async (to, _from, next) => {
      //     if (!to.params.identifier) {
      //       next({ path: '/developer-tools/fields', query: { organization: to.query.organization } });
      //     } else {
      //       const fields = await FieldService.getInstance(getGrpcClient).listFields({
      //         selection: { search: to.params.identifier },
      //       });
      //       if (fields.fields.length) {
      //         next();
      //       } else {
      //         next({ path: '/developer-tools/fields', query: { organization: to.query.organization } });
      //       }
      //     }
      //   },
      //   meta: {
      //     label: 'Edit field',
      //   },
      // },
      {
        path: 'tokens/:name/update',
        name: 'token-edit',
        component: () => import('../pages/developer-tools/TokenManagement.vue'),
        props: true,
        beforeEnter: async (to, _from, next) => {
          if (!to.params.name) {
            next({ path: '/developer-tools/tokens', query: { organization: to.query.organization } });
          } else {
            const tokens = await TokensService.getInstance(getGrpcClient).list({
              selection: { name: to.params.name },
            });
            if (tokens.tokens.length) {
              next();
            } else {
              next({ path: '/developer-tools/tokens', query: { organization: to.query.organization } });
            }
          }
        },
        meta: {
          label: 'Edit token',
        },
      },
      {
        path: 'mqtt/:id/update',
        name: 'mqtt-edit',
        component: () => import('../pages/developer-tools/MqttManagement.vue'),
        props: true,
        beforeEnter: async (to, _from, next) => {
          if (!to.params.id) {
            next({ path: '/developer-tools/mqtt', query: { organization: to.query.organization } });
          } else {
            const publishersResponse = await MqttService.getInstance(getGrpcClient).list({
              selection: { publishers: { include: [to.params.id] } },
            });
            if (publishersResponse.publishers.length) {
              next();
            } else {
              next({ path: '/developer-tools/mqtt', query: { organization: to.query.organization } });
            }
          }
        },
        meta: {
          label: 'Edit MQTT Publisher',
        },
      },
      {
        path: 'certificates/:id/update',
        name: 'certificate-edit',
        component: () => import('../pages/developer-tools/CertificateManagement.vue'),
        props: true,
        beforeEnter: async (to, _from, next) => {
          if (!to.params.id) {
            next({ path: '/developer-tools/certificates', query: { organization: to.query.organization } });
          } else {
            const certificatesResponse = await CertificatesService.getInstance(getGrpcClient).list({
              selection: { certificateIds: [to.params.id] },
            });
            if (certificatesResponse.certificates.length) {
              next();
            } else {
              next({ path: '/developer-tools/certificates', query: { organization: to.query.organization } });
            }
          }
        },
        meta: {
          label: 'Edit Certificate',
        },
      },
      {
        path: 'slack/:id/update',
        name: 'slack-edit',
        component: () => import('../pages/developer-tools/SlackPublisherManagement.vue'),
        props: true,
        beforeEnter: async (to, _from, next) => {
          if (!to.params.id || Number.isNaN(parseInt(to.params.id))) {
            next({ name: 'developer-tools', query: { organization: to.query.organization } });
          } else {
            const slackPublishers = await SlackIntegrationService.getInstance(getGrpcClient).list({
              selection: { publishers: { include: [to.params.id] } },
            });
            if (slackPublishers.publishers.length) {
              next();
            } else {
              next({ name: 'view-developer-tools', query: { organization: to.query.organization } });
            }
          }
        },
        meta: {
          label: 'Edit Slack integration',
        },
      },
    ],
  },
  changelog: {
    path: '/changelog',
    redirect: '/changelog/mission-control',
    component: () => import('../pages/changelog/ChangelogPage.vue'),
    meta: {
      label: 'Changelog',
    },
    children: [
      {
        fullPath: '/changelog/mission-control',
        path: 'mission-control',
        component: () => import('../pages/changelog/MissionControlPage.vue'),
        meta: {
          label: 'Mission Control',
        },
      },
      {
        fullPath: '/changelog/customer-api',
        path: 'customer-api',
        component: () => import('../pages/changelog/CustomerApiPage.vue'),
        meta: {
          label: 'API',
        },
      },
      {
        fullPath: '/changelog/support-api',
        path: 'support-api',
        component: () => import('../pages/changelog/SupportApiPage.vue'),
        meta: {
          label: 'Operations',
          can: Rule.CUSTOMER_SUPPORT,
          onDeniedRoute: '/changelog/mission-control',
        },
      },
      {
        fullPath: '/changelog/cli',
        path: 'cli',
        component: () => import('../pages/changelog/CliPage.vue'),
        meta: {
          label: 'CLI',
        },
      },
    ],
  },
  support: {
    fullPath: '/login/support',
    path: 'support',
    name: 'support',
    component: () => import('../pages/login/ZendeskPage.vue'),
    meta: { requiresAuth: true },
  },
  user: {
    path: '/user',
    redirect: '/user/profile',
    component: () => import('../pages/user/UserPage.vue'),
    children: [
      {
        fullPath: '/user/profile',
        path: 'profile',
        name: 'profile-settings',
        component: () => import('../pages/user/Profile.vue'),
        meta: {
          label: 'Personal profile',
        },
      },
      {
        fullPath: '/user/general',
        path: 'general',
        alias: '/settings/general',
        name: 'general-settings',
        component: () => import('../pages/user/GeneralSettings.vue'),
        meta: {
          label: 'Units & date-time format',
        },
      },
      {
        fullPath: '/user/email-preferences',
        path: 'email-preferences',
        alias: '/settings/email-preferences',
        name: 'email-preferences',
        component: () => import('../pages/user/EmailPreferences.vue'),
        meta: {
          label: 'Email preferences',
        },
      },
    ],
  },
  login: {
    path: '/login',
    component: () => import('../templates/HomeLayout.vue'),
  },
  mfa: {
    fullPath: '/login/mfa',
    path: 'mfa',
    //component: () => import('../templates/HomeLayout.vue'),
    meta: { transition: 'slide-fade-left', requiresAuth: true },
    children: [
      {
        fullPath: '/login/mfa/enroll',
        path: 'enroll',
        component: () => import('../pages/login/MfaEnroll.vue'),
      },
      {
        path: '',
        component: () => import('../pages/login/Mfa.vue'),
      },
    ],
  },
  signIn: {
    path: '',
    component: () => import('../pages/login/SigninPage.vue'),
    meta: { transition: 'slide-fade-left' },
  },
  tokenLogin: {
    path: '/login/token',
    component: () => import('../pages/login/TokenLogin.vue'),
    meta: { transition: 'slide-fade-right', can: Rule.TOKEN_LOGIN, onDeniedRoute: '/login' },
  },
  createUser: {
    fullPath: '/login/create',
    path: 'create',
    component: () => import('../pages/login/CreateUserPage.vue'),
    meta: { transition: 'slide-fade-right' },
  },
  forgotPassword: {
    fullPath: '/login/forgot-password',
    path: 'forgot-password',
    component: () => import('../pages/login/ForgotPasswordPage.vue'),
    meta: { transition: 'slide-fade-right' },
  },
  emailVerification: {
    fullPath: '/login/email-verification',
    path: 'email-verification',
    component: () => import('../pages/login/EmailVerificationPage.vue'),
    meta: { transition: 'slide-fade-left', requiresAuth: true },
    beforeEnter: async (to, from) => {
      try {
        if (!from.fullPath.includes('/login/create')) {
          const { verifyEmail } = useEmailVerification();
          await verifyEmail();
        }
      } catch (e) {
        to.params.isError = true;
      }
    },
  },
  termsAndConditions: {
    fullPath: '/login/terms-and-conditions',
    path: 'terms-and-conditions',
    component: () => import('../pages/login/TermsAndConditionsPage.vue'),
    meta: { transition: 'slide-fade-left', requiresAuth: true },
  },
  organizations: {
    path: '/organization',
    component: () => import('../templates/HomeLayout.vue'),
    redirect: '/organization/requests',
  },
  organizationsRequest: {
    fullPath: '/organization/requests',
    path: 'requests',
    component: () => import('../pages/organization/OrganizationRequestsPage.vue'),
    meta: { transition: 'slide-fade-right', requiresAuth: true },
  },
  organizationsJoin: {
    fullPath: '/organization/join',
    path: 'join',
    component: () => import('../pages/organization/JoinOrganizationPage.vue'),
    meta: { transition: 'slide-fade-left', requiresAuth: true },
  },
  organizationsInvites: {
    fullPath: '/organization/invites',
    path: 'invites',
    component: () => import('../pages/organization/OrganizationInvitesPage.vue'),
    meta: { transition: 'slide-fade-right', requiresAuth: true },
  },
  organizationsCreate: {
    fullPath: '/organization/create',
    path: 'create',
    component: () => import('../pages/organization/CreateOrganizationPage.vue'),
    meta: { transition: 'slide-fade-left', requiresAuth: true },
  },
};

export default [
  {
    ...routes.login,
    children: [
      routes.signIn,
      routes.tokenLogin,
      routes.createUser,
      routes.forgotPassword,
      routes.emailVerification,
      routes.mfa,
      routes.termsAndConditions,
      routes.support,
    ],
  },
  {
    ...routes.organizations,
    children: [
      routes.organizationsRequest,
      routes.organizationsJoin,
      routes.organizationsInvites,
      routes.organizationsCreate,
    ],
  },
  {
    ...routes.dashboard,
    children: [
      routes.hilo,
      routes.devices,
      routes.assets,
      routes.changelog,
      routes.user,
      routes.settings,
      routes.transfers,
      routes.developer,
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: () => {
      return { path: '/' };
    },
  },
];
