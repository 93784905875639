import { Client } from 'nice-grpc-web';
import { DeepPartial } from '../customer_compiled/field_service';
import { GrpcClientFactoryType } from './types';
import {
  CreateTagRequest,
  DeleteTagRequest,
  DeleteTagRequest_Response,
  ListTagsRequest,
  ListTagsRequest_Response,
  Tag,
  TagHealth_Request,
  TagHealth_Response,
  TagServiceDefinition,
  UpdateTagRequest
} from '../customer_compiled/tag';

export default class TagService {
  private static instance: TagService;
  private readonly client: Client<typeof TagServiceDefinition>;

  private constructor(grpcClientFactory: GrpcClientFactoryType) {
    this.client = grpcClientFactory<typeof TagServiceDefinition>(
      TagServiceDefinition,
    );
  }

  static getInstance(grpcClientFactory: GrpcClientFactoryType) {
    if (!TagService.instance) {
      TagService.instance = new TagService(grpcClientFactory);
    }

    return TagService.instance;
  }

  fetchTags(payload: DeepPartial<ListTagsRequest>): Promise<ListTagsRequest_Response> {
    const request = ListTagsRequest.fromPartial(payload);
    return this.client.list(request);
  }

  update(payload: DeepPartial<UpdateTagRequest>): Promise<Tag> {
    const request = UpdateTagRequest.fromPartial(payload);
    return this.client.update(request);
  }

  create(payload: DeepPartial<CreateTagRequest>): Promise<Tag> {
    const request = CreateTagRequest.fromPartial(payload);
    return this.client.create(request);
  }

  delete(payload: DeepPartial<DeleteTagRequest>): Promise<DeleteTagRequest_Response> {
    const request = DeleteTagRequest.fromPartial(payload);
    return this.client.delete(request);
  }
  healthCount(payload: DeepPartial<TagHealth_Request>): Promise<TagHealth_Response> {
    const request = TagHealth_Request.fromPartial(payload);
    return this.client.health(request);
  }
}
