import { createI18n as _createI18n, I18n } from 'vue-i18n';

import messages from '@intlify/unplugin-vue-i18n/messages';

export const SUPPORT_LOCALES = ['en', 'nl'];

export function createI18n(): I18n {
  return _createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'en',
    fallbackLocale: 'en',
    messages,
  });
}
