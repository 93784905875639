import { defineStore } from 'pinia';
import { Asset } from 'hiber-grpc/src/customer_compiled/asset';

export const useAssetMasterDetails = defineStore('assetMasterDetails', {
  state: () =>
    ({
      _selectedItem: null,
    } as { _selectedItem: Asset | null }),
  actions: {
    setSelectedItem(item: Asset | null) {
      this._selectedItem = item;
    },
  },
  getters: {
    selectedItem(state) {
      return state._selectedItem;
    },
  },
});
